import React, { useEffect, useRef, useMemo } from "react";
import { Box, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import { useStaking, StakingActionType } from "@contexts/StakingContext";
import { StakingPool } from "../../types/staking";
import { useTranslation } from "react-i18next";
import PoolCardButton from "./PoolCardButton";
import PoolCardStaking from "./PoolCardStaking";
import { numberDisplay } from "../../utils/valueDisplayHelper";
import {
  useLocation
} from 'react-router-dom'
import { ContractName } from "../../contexts/ContractsContext";

const cardImage = {
  // [StakingPool.Wars]: "WARS_staking.png",
  [StakingPool.Wars]: "LP_staking.png",
  [StakingPool.WarsBusdLp]: "LP_staking.png",
  [StakingPool.WxMStakingW]: "LP_staking.png",

  [StakingPool.WarsBusdLp1]: "LP_staking.png",
  [StakingPool.WarsBusdLp2]: "LP_staking.png",
  [StakingPool.WarsBusdLpGAM]: "LP_staking.png",

};
const PoolCard = ({ pool, id, showFinished }: { pool: StakingPool, id: string, showFinished: boolean }) => {
  const location = useLocation()
  const poolCardRef = useRef(null)
  const { t } = useTranslation("staking");
  const { [pool]: stakingPool, activeEvents: activeEventsProp } = useStaking();
  const { values, actions } = stakingPool || {};
  const activeEvents = React.useMemo(() => {
    return activeEventsProp?.pool === pool ? activeEventsProp : undefined;
  }, [activeEventsProp, pool]);

  useEffect(() => {

    if (location.hash && poolCardRef.current) {
      let hashId = location.hash.slice(1, location.hash.length)
      if (hashId === id) {
        let scrollIntoViewTimeout = setTimeout(() => {
          poolCardRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })

        }, 1000)
        return () => {
          clearTimeout(scrollIntoViewTimeout)
        }
      }
    }

  }, [location, poolCardRef.current, id])

  if (!stakingPool) return null;

  const poolDurationDays = Number(values.poolDurationDays) == -1 ? 30 : Number(values.poolDurationDays);
  const apr = Number(values.poolRewardAmount) / Number(values.totalValueLocked) / poolDurationDays * 365 / 2 * 100;

  let { contractName } = values

  return (values.isFinished && !showFinished) || (!values.isFinished && showFinished) ? null : (
    <Grid item xs={12} md={6}>
      <Box
        ref={poolCardRef}
        id={id}
        component={Paper}
        p={4}
        sx={{
          borderRadius: 2,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url("./assets/${cardImage[pool]}")`,

        }}
      >
        <Box mb={2} display="flex" alignItems="center">
          <Box>
            <Typography variant="h1" color="secondary" fontWeight="bold" display="inline" sx={{ textShadow: "0px 2px 4px black" }}>
              {values.stakingPoolTitle}
            </Typography>
            {/* <Typography variant="h1" display="inline" fontWeight="bold" sx={{ textShadow: "0px 1px 2px black" }}>
            {` ${values.stakedTokenLabel.split(" ").slice(-1)}`}
          </Typography> */}
          </Box>
          <Box flex={1} mx={1}>
            <Divider />
          </Box>
        </Box>
        <Box>
          {
            values.hasApr && <Typography variant="h3" fontWeight="bold">
              {`${t("field.apr.label")}: ${numberDisplay(apr)}%`}
            </Typography>
          }
          {
            // contractName !== ContractName.WxMStakingW && <Typography variant="h3" fontWeight="bold">
            //   {`${t("field.gamEarned.label")}: ${numberDisplay(values.pendingReward)}`}
            // </Typography>
          }
          <Typography variant="h3" fontWeight="bold">
            {`${t("field.tvl.label")}: ${numberDisplay(values.totalValueLocked)}`}
          </Typography>
          {
            contractName !== ContractName.WARSStaking ? <Typography variant="h3" fontWeight="bold">
              {`${t("field.stakedWARSLP.label")}: ${numberDisplay(values.amountStaked)}`}
            </Typography> :
              <Typography variant="h3" fontWeight="bold">
                {`${t("field.stakedWARS.label")}: ${numberDisplay(values.amountStaked)}`}
              </Typography>
          }
          <Typography variant="h3" fontWeight="bold">
            {`${t("field.claimable.label")}: ${numberDisplay(values.pendingReward)} ${values.rewardTokenLabel}`}
          </Typography>
        </Box>
        <Grid my={"0px"} container display="flex" justifyContent="space-between" alignItems="end">
          <Box>
            <ul style={{ paddingLeft: "20px" }}>
              {values.featuresLabel.map((label) => (
                <li key={label}>
                  <Typography variant="h5">{t(label)}</Typography>
                </li>
              ))}
              {values.tradeLink && (
                <li>
                  <Link href={values.tradeLink} target="_blank">
                    Add {values.stakedTokenLabel}
                  </Link>
                </li>
              )}
            </ul>
          </Box>

          <Grid container height="90px" item xs={12} alignItems="center" justifyContent="center">
            {
              // contractName !== ContractName.WxMStakingW && 
              <Grid item container justifyContent="center" xs>
                <PoolCardButton
                  type={StakingActionType.Harvest}
                  activeEvents={activeEvents}
                  disabled={!actions[StakingActionType.Harvest]}
                  onClick={actions[StakingActionType.Harvest]}
                />
              </Grid>
            }
            <Grid item container justifyContent="center" xs>
              <PoolCardStaking
                pool={pool}
                activeEvents={activeEvents}
                disabled={!actions[StakingActionType.Stake]}
                onStake={actions[StakingActionType.Stake]}
              />
            </Grid>
            <Grid item container justifyContent="center" xs>
              <PoolCardButton
                type={StakingActionType.Unstake}
                activeEvents={activeEvents}
                disabled={!actions[StakingActionType.Unstake]}
                onClick={actions[StakingActionType.Unstake]}
              />
            </Grid>
          </Grid>

        </Grid>
      </Box>
    </Grid>
  );
};
export default PoolCard;
