import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLottery } from "@contexts/LotteryContext";

const UnfoundBalance = () => {
  const { t } = useTranslation("lottery");
  const { availablePlanets = 0 } = useLottery();
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" pb={1}>
        {t("field.unfound_balance.label")}
      </Typography>
      <Typography color="#E5705C" variant="h4" fontWeight="bold" fontFamily="Poppins" pb={1}>
        {availablePlanets}
      </Typography>
    </Box>
  );
};
export default UnfoundBalance;
