import React from "react";
import Greeting from "@containers/wallet/Greeting";
import { Box, Container, Grid, Typography } from "@mui/material";
import SocialMediaBanner from "@components/shared/SocialMediaBanner";
import AvailableToClaim from "@containers/vesting/AvailableToClaim";
import ClaimedAmount from "@containers/vesting/ClaimedAmount";
import ClaimVesting from "@containers/vesting/ClaimVesting";
import VestingProgress from "@containers/vesting/VestingProgress";
import { useTranslation } from "react-i18next";
import Title from "@components/shared/Title";
import FusionImg from '../images/fusion800.png'
import Banner from "@components/shared/Banner";

const PlanetFusion = () => {
  const { t } = useTranslation("planetFusion");
  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item xs={12} justifyContent="flex-start">
            <Title name={"Planet Fusion"} hasBanner={false}/>
          </Grid>

          <Banner
            img={`${process.env.PUBLIC_URL}/images/metagodsBanner.png`}
            url="https://info-137.gitbook.io/metagods-next-generation-nft-action-rpg-game/dual-token-economy-usdmgod-and-usdrelic/usdmgod"
          />

          <Grid item xs={12}>
            <img style={{width: "100%", height: '100%'}} src={FusionImg} />
          </Grid>
          
        </Grid>
      </Box>
    </Container>
  );
};
export default PlanetFusion;
