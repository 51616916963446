import React, { ChangeEvent, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Search from "@mui/icons-material/Search";
import { toTitleCase } from "@utils/valueDisplayHelper";

const CardFilter = (props) => {
  const { searchText, onSearchChange, filters, onFilterChange } = props;
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent={props.isMobile ? "flex-start" : "end"}>
        <TextField
          size="small"
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}

          InputProps={{
            notched: false,
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
        <IconButton onClick={() => setIsExpand(!isExpand)}>
          <FilterAlt />
        </IconButton>
      </Box>
      <Box pt={1} className={`card-filter ${isExpand ? "active" : ""}`}>
        {Object.entries(filters).map(([key, options]: [string, string[]]) => (
          <Box key={key} display="flex" alignItems="center">
            <Typography pr={2} variant="h4">{key}</Typography>
            <FormGroup row>
              {options.map((option) => (
                <FormControlLabel
                  key={option}
                  control={<Checkbox />}
                  label={option}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilterChange(key, option, e.target.checked)}
                />
              ))}
            </FormGroup>
          </Box>
        ))}
      </Box>
    </Box>
  )
};

export default CardFilter;