import { initializeApp, getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";



const firebaseConfig = {
  apiKey: "AIzaSyBZ34gdIT-sxaO-bQDhU7K9RpwYv78u-Y0",
  projectId: "metawars-fa9f3",
  appId: "1:761930888443:web:f5c5de5b871501b3242c5e",
  measurementId: "G-BQDPS7X3CC",
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const analytics = getAnalytics(getApp());
export const functions = getFunctions(getApp());
export const db = getFirestore(getApp());
console.log('db', db)
if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8080);
}
