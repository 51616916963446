const lottery = {
  title: "Planet NFT Sale",
  "information": "Information",
  "journey result": "JOURNEY RESULT",
  "description.1":
    "Earn rewards by holding planets. The higher tier you get, the more reward of our partnership projects you gain. HODL your planet!",
  "description.2":
    "Various bonus permissions are avilable in each tier based on rarity.",
  "description.3": "To be discovered...",
  "field.total_supply.label": "Total Planet NFT Supply",
  "field.unfound_balance.label": "Undiscovered Planet NFTs",
  "field.your_balance.label": "Your Planets Balance",
  "field.cost": "Cost: {{cost}} BNB",
  "field.journey": "{{quantity}} Journey{{suffix}}",
  "draw.title": "Start Investigate",
  "draw.wrong_chain": "Please switch to BSC Chain",
  "draw.connect": "Connect to purchase",
  "draw.waiting": "Waiting for Confirmation",
  "draw.deposited": "Successfully deposited. Please wait...",
  "draw.completed": "Successfully discovered {{count}} Orb{{suffix}}!",
  "draw.step.3.10": "10 New Orbs Discovered",
  "draw.step.3.1": "A New Orb Discovered",
  "draw.step.0": "Choose your journeys",
  "draw.step.1": "Please confirm in wallet",
  "draw.step.2": "You're ready...",
};
export default lottery;
