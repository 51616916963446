import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLottery } from "@contexts/LotteryContext";

const TotalSupply = () => {
  const { t } = useTranslation("lottery");
  const { maxPlanets = 0 } = useLottery();
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" pb={1}>
        {t("field.total_supply.label")}
      </Typography>
      <Typography color="#E5705C" variant="h4" fontWeight="bold" fontFamily="Poppins" pb={1}>
        {maxPlanets}
      </Typography>
    </Box>
  );
};
export default TotalSupply;
