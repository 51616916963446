import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import RaceGameDisplayContainer from "./RaceGameDisplayContainer";
import RaceFuelContainer from "./RaceFuelContainer";
import { useRaceGame } from "@contexts/RaceGameContext";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { colors } from "@utils/Constants";

type RaceGameContainerProps = {

};

const RaceGameContainer = (props: RaceGameContainerProps) => {
  const { record, claimStage } = useRaceGame();
  const { isConnected, isChainIdCorrect } = useConnectWallet();
  const max = 1500;
  const milestones = [];
  for (let i = 60; i < max; i += 60) {
    milestones.push(i);
  }
  const [counter, setCounter] = useState(0);
  const actualCounter = counter + record * 3;

  const addCounter = () => {
    if (actualCounter >= max) return;

    setCounter((prev) => prev + 1);
  }

  useEffect(() => {
    setCounter(0);
  }, [record]);

  return (
    <Grid container rowGap={4} width="1200px" height="100%" columnGap={1}>
      <Grid item container width="800px" height="580px" position="relative">
        <RaceGameDisplayContainer counter={actualCounter} max={max} milestones={milestones} addCounter={addCounter}/>
        {!isConnected || !isChainIdCorrect || claimStage !== 0 ? (
          <Box position="absolute" width="100%" height="100%" sx={{ bgcolor: "rgba(0, 0, 0, 0.5)" }}>
            <Box zIndex={4} position="absolute" width="100%" height="50%" top={0} bottom={0} mt="auto" mb="auto" textAlign="center"  >
              <img src={`${process.env.PUBLIC_URL}/assets/warning.png`} />
              {!isConnected ? (
                  <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>Connect to play</Typography>
              ) : (
                isConnected && !isChainIdCorrect ? (
                    <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>Please switch to BSC Chain</Typography>
                ) : (
                  claimStage !== 0 ? (
                      <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>Please confirm in wallet</Typography>
                  ) : null
                )
              )}
            </Box>
          </Box>
        ) : null}
      </Grid>
      <Grid item container width="340px" height="580px" flexDirection="column">
        <RaceFuelContainer counter={counter} />
      </Grid>
    </Grid>
  );
};

export default RaceGameContainer;