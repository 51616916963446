import { Button, ButtonProps, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "@utils/Constants";
import { useCallback } from "react";

const Banner = (props) => {
  const { img, url, comingSoon, completed, grayOut } = props

  const isComingSoon = comingSoon
  const isCompleted = completed

  const opacity = (grayOut || isComingSoon || isCompleted) ? 0.5 : 1

  const onBannerClick = useCallback(() => {
    if (url) {
      window.open(url, "_blank")
    }
  }, [url])

  return (
    <Grid container item xs={12} sx={{ position: "relative", opacity }}>
      <img onClick={onBannerClick} src={img} style={{ width: "100%" }} />
      {isComingSoon && (
        <Typography sx={{ fontSize: "26px", position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)" }}>Coming soon ...|</Typography>
      )}
      {isCompleted && (
        <Typography sx={{ fontSize: "26px", position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)" }}>Finished</Typography>
      )}
    </Grid>
  );
};

export default Banner;


