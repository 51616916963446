import React, { createContext, useContext, useReducer } from "react";
import { ethers, Transaction } from "ethers";
import { useConnectWallet } from "./ConnectWalletContext";
import { useContracts, ContractName } from "./ContractsContext";
import { useErrorNotification } from "./ErrorNotificationContext";
import Debug from "debug";
import { Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const debug = Debug("web:lottery");

export type NFT = {
  id: string;
  tokenId: string;
};
export interface LotteryProps {
  pricePerPlanet: number;
  maxPlanets: number;
  availablePlanets: number;
  balance: number;
  activeStep: number;
  submit: (v: number) => void;
  drawnPlanets?: NFT[];
  raffleSummary?: {
    transaction: Transaction;
  };
  reset: () => void;
  errors: {
    collectedPlanets?: boolean;
  };
}

export const LotteryContext = createContext<LotteryProps>({
  pricePerPlanet: 0,
  maxPlanets: 0,
  availablePlanets: 0,
  balance: 0,
  submit: () => {},
  reset: () => {},
  activeStep: 0,
  errors: {},
});

export const useLottery = () => {
  const context = useContext(LotteryContext);

  return context;
};

const initialState = {
  pricePerPlanet: 0,
  activeStep: 0,
  errors: {},
};

const reducer = (state, { type, ...actionProps }) => {
  switch (type) {
    case "init": {
      return {
        ...initialState,
        ...actionProps,
      };
    }
    case "update": {
      return { ...state, ...actionProps };
    }
    case "update-step": {
      return {
        ...state,
        activeStep: actionProps.step,
      };
    }
    case "discovered": {
      return {
        ...state,
        drawnPlanets: actionProps.planets,
      };
    }
    case "update-summary": {
      return {
        ...state,
        activeStep: 2,
        raffleSummary: {
          transaction: actionProps.transaction,
        },
      };
    }
    case "discover-error": {
      return {
        ...state,
        errors: {
          ...state.errors,
          [actionProps.errorType]: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const LotteryProvider: React.FC = ({ children }) => {
  const {
    [ContractName.PlanetLottery]: contract,
    [ContractName.Planet]: planet,
  } = useContracts();
  const { t } = useTranslation("lottery");
  const { address } = useConnectWallet();
  const { addError } = useErrorNotification();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [successMsg, setSuccessMsg] = React.useState("");

  const retrieveContractValues = React.useCallback(async () => {
    debug("retrieving contract values");
    const pricePerPlanet = await contract?.pricePerPlanet();
    debug(`pricePerPlanet ok`);
    const mintedCount = await contract?.mintedCount();
    debug(`mintedCount ok`);
    const maxPlanets = await contract?.MAX_PLANETS();
    debug(`maxPlanets ok`);
    const balance = address ? await planet?.balanceOf(address) : undefined;
    debug(`balance ok`);

    const availablePlanets =
      (maxPlanets?.toNumber() || 0) - (mintedCount?.toNumber() || 0);
    debug(`availablePlanets ok`);

    return {
      availablePlanets,
      maxPlanets: maxPlanets ? maxPlanets.toNumber() : undefined,
      balance: balance ? balance.toNumber() : 0,
      pricePerPlanet: pricePerPlanet
        ? parseFloat(ethers.utils.formatEther(pricePerPlanet))
        : undefined,
    };
  }, [contract, address, planet]);

  const updateStep = React.useCallback((step: number) => {
    dispatch({
      type: "update-step",
      step,
    });
  }, []);

  const discoveredListner = React.useCallback(
    async (planetIds) => {
      try {
        debug(`discover listener: retrieving contract values`);
        const updatedPoolValues = await retrieveContractValues();
        dispatch({
          type: "update",
          ...updatedPoolValues,
        });
        updateStep(3);
        setSuccessMsg(
          t("draw.completed", {
            count: planetIds?.length,
            suffix: planetIds?.length > 1 ? "s" : "",
          })
        );
      } catch (e) {
        debug(`discover listener: ${e.message}`);
        addError({
          label: e.data?.message || e.message,
        });
        dispatch({
          type: "discover-error",
          errorType: "collectedPlanets",
        });
      } finally {
        dispatch({
          type: "discovered",
          planets: planetIds,
        });
      }
    },
    [retrieveContractValues, updateStep, t, addError]
  );
  const depositListener = React.useCallback(async () => {
    setSuccessMsg(t("draw.deposited"));
  }, [t]);

  React.useEffect(() => {
    if (contract && state.activeStep === 2) {
      contract.on("Discovered", (receiverAddress, tokenIds) => {
        if (receiverAddress === address) {
          discoveredListner(tokenIds);
        }
      });
      contract.on("Deposited", (receiverAddress, amount) => {
        if (receiverAddress === address) {
          depositListener();
        }
      });
    }
    return () => {
      if (contract) {
        contract.removeAllListeners();
      }
    };
  }, [contract, discoveredListner, address, depositListener, state.activeStep]);

  const init = React.useCallback(async () => {
    if (contract && planet) {
      try {
        debug("init start");
        const contractValues = await retrieveContractValues();
        dispatch({
          type: "init",
          ...contractValues,
        });
        debug("init complete");
      } catch (e) {
        debug(`init error: ${e.message}`);
      }
    }
  }, [contract, retrieveContractValues, planet]);

  const submit = React.useCallback(
    async (value: number) => {
      try {
        updateStep(1);
        const tx = await contract.discover({
          value: ethers.utils.parseEther(`${value}`),
        });
        dispatch({
          type: "update-summary",
          transaction: tx,
        });
        await tx.wait();
      } catch (e) {
        debug(e.message);
        addError({
          label: e.data?.message || e.message,
        });
        updateStep(0);
      }
    },
    [contract, updateStep, addError]
  );

  React.useEffect(() => {
    init();
  }, [init]);

  const value = React.useMemo(() => {
    return { ...state, submit, reset: init };
  }, [state, submit, init]);
  return (
    <LotteryContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!successMsg}
        onClose={() => setSuccessMsg("")}
        autoHideDuration={3000}
        message={successMsg}
        ContentProps={{
          sx: {
            background: (theme) => theme.palette.background.default,
            border: (theme) => `2px solid ${theme.palette.primary.main}`,
            color: "#fff",
            fontSize: "1.25rem",
            textAlign: "center",
          },
        }}
      />
    </LotteryContext.Provider>
  );
};
