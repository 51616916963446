import { Box, Button, Grid } from "@mui/material"
import { Carousel } from "react-responsive-carousel";
import "./Lottery.css";

const LotteryPreview = (props) => {
  const { src, title } = props
  const labels = ["Intro", "New Characters", "Chance"]

  return (
    <Carousel
      autoPlay
      interval={8000}
      infiniteLoop
      swipeable
      emulateTouch
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      renderIndicator={(clickHandler, isSelected, index, label) => {
        return (
          <Button
            variant="contained"
            color={isSelected ? "primary" : "secondary"}
            onClick={clickHandler}
            sx={{ marginLeft: "5px", marginRight: "5px", minWidth: "unset" }}
          >
            {labels[index]}
          </Button>
        );
      }}
      renderArrowPrev={(clickHandler, hasPrev, label) => (
        hasPrev ? (
          <button className="lottery-control-arrow control-prev" onClick={clickHandler}>
            <img style={{ width: "26px" }} src={`${process.env.PUBLIC_URL}/assets/draw_gallary/arrow_left.png`} />
          </button>
        ) : null
      )}
      renderArrowNext={(clickHandler, hasNext, label) => (
        hasNext ? (
          <button className="lottery-control-arrow control-next" onClick={clickHandler}>
            <img style={{ width: "26px" }} src={`${process.env.PUBLIC_URL}/assets/draw_gallary/arrow_right.png`} />
          </button>
        ) : null
      )}
    >
      <div>
        <img src={`${process.env.PUBLIC_URL}/assets/draw_gallary/gallary1.png`} />
      </div>
      <div>
        <img src={`${process.env.PUBLIC_URL}/assets/draw_gallary/gallary2.png`} />
      </div>
      <div>
        <img src={`${process.env.PUBLIC_URL}/assets/draw_gallary/gallary3.png`} />
      </div>
    </Carousel>
  )
}

export default LotteryPreview