import { useRaceGame } from "@contexts/RaceGameContext";
import { Button, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colors } from "@utils/Constants";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PopinsTypography = styled(Typography)`
  font-family: Poppins;
  margin: 2px 0px;
`

type RaceFuelContainerProps = {
  counter: number;
};

let intervalId;

const RaceFuelContainer = (props: RaceFuelContainerProps) => {
  const { t } = useTranslation("raceGame");
  const [claimDisabled, setClaimDisabled] = useState(false);
  const { record, remaining, claimStage, claimSuccess, claimFailed, burnAndClaim, refresh, refreshRecord, resetClaim }  = useRaceGame();

  const { counter } = props;
  const gams = Math.floor(counter / 3);
  const craces = gams;

  useEffect(() => {
    if (refresh) {
      intervalId = setInterval(refresh, 3000);
    }

    return () => clearInterval(intervalId);
  }, [refresh]);

  const handleBurnAndClaim = useCallback(() => {
    setClaimDisabled(true);
    burnAndClaim(gams);
  }, [counter]);

  useEffect(() => {
    if (record > 0) {
      setClaimDisabled(false);
    }
  }, [record]);

  useEffect(() => {
    if (claimSuccess) {
      refreshRecord();
      resetClaim();
    }
  }, [claimSuccess]);

  useEffect(() => {
    if (claimFailed) {
      setClaimDisabled(false);
      resetClaim();
    }
  }, [claimFailed]);

  return (
    <Grid container width="100%" height="100%" sx={{
      border: "2px solid gray",
      borderRadius: "16px",
    }}>
      <Grid container item xs={12} sx={{
        borderBottom: `1px solid #fff`,
        padding: "16px 14px 12px 14px"

      }}>
        <Grid container item xs={12} justifyContent={"center"}>
          <Box sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/race_game/logo.png)`,
            backgroundSize: "100% 100%",
            aspectRatio: "502 / 142",
            width: "75%"

          }}></Box>
        </Grid>
        <Grid container item xs={12}>
          <PopinsTypography fontSize="16px" fontWeight="bold" color={colors.paleRedColor}>Information</PopinsTypography>
        </Grid>
        <Grid container item xs={12}>
          <PopinsTypography paragraph>{t('description.1')}</PopinsTypography>
        </Grid>

      </Grid>

      <Grid container item xs={12}
        sx={{
          padding: "10px 14px 16px 14px"

        }}
      >
        <PopinsTypography width="100%" fontWeight="bold" lineHeight="20px" fontSize="20px">REMAINING TOKENS</PopinsTypography>
        <PopinsTypography width="100%" fontWeight="bold" color={colors.orangeColor} lineHeight="28px" fontSize="28px">{remaining} GAM</PopinsTypography>
        <PopinsTypography pt="20px" width="100%" fontWeight="bold" lineHeight="20px" fontSize="20px">FUEL TO BURN</PopinsTypography>
        <PopinsTypography width="100%" fontWeight="bold" color={colors.orangeColor} lineHeight="28px" fontSize="28px">{gams} GAM</PopinsTypography>
        <PopinsTypography pt="20px" width="100%" fontWeight="bold" lineHeight="20px" fontSize="20px">REWARD TO CLAIM</PopinsTypography>
        <PopinsTypography pb="20px" width="100%" fontWeight="bold" color={colors.orangeColor} lineHeight="28px" fontSize="28px">{craces} CRACE</PopinsTypography>
        <Grid pb="20px" container item xs={12} justifyContent={"center"}>
          <Button
            sx={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/race_game/btn_claim.png)`,
              aspectRatio: `350 / 100`,
              backgroundSize: "100% 100%",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "20px",
              height: "50px",
              fontFamily: "Poppins",
              backgroundColor: "transparent"
            }}
            variant="contained"
            disabled={gams === 0 || remaining < craces || claimDisabled}
            onClick={handleBurnAndClaim}
          >
            BURN and CLAIM
          </Button>
        </Grid>
        <PopinsTypography width="100%" fontSize="10px">*Transaction will only valid when you approve and sign the transaction on MetaMask. Transaction might be failed when all $CRACE is claimed. $GAM will be permanently burned when the claiming transaction is approved.</PopinsTypography>
      </Grid>
    </Grid>
  );
};

export default RaceFuelContainer;
