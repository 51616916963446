import { useEffect, useState } from "react";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import { map } from "lodash";
import axios from "axios";

const useLootboxes = () => {
  const [lootboxes, setLootboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { address } = useConnectWallet();
  const { addError } = useErrorNotification();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const dataSourceUrl = `https://api.metawars.gg/nft/contracts/0x78ff0ac0df21b654e6ab570a988eadbd22214583/nftOwners/${address}/tokens`;
        let response = await fetch(dataSourceUrl);
        const result = await response.json();

        let promiseAllRes = await Promise.all(
          map(result, (e, idx) => {
            return axios.get(`${process.env.REACT_APP_MECH_INFO}${e.tokenId}`)
          })
        )

        for (let i = 0; i < result.length; i++) {
          result[i] = {
            ...result[i],
            ...promiseAllRes[i]['data'],
            isLootbox: true
          }
        }

        setLootboxes(result)
      } catch (err) {
        addError({ label: err.message });
      } finally {
        setIsLoading(false);
      }
    }

    if (!!address) {
      fetchData()
    } else {
      setLootboxes([])
      setIsLoading(false);
    }
  }, [address]);

  return { lootboxes, isLoading };
};

export default useLootboxes;