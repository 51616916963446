import { useCallback, useEffect, useState } from "react";
import Greeting from "../containers/wallet/Greeting";
import { Box, CircularProgress, Container, Divider, Grid, Typography } from "@mui/material";
import ListInventory from "@containers/inventory/ListInventory";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { colors } from "@utils/Constants";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import Title from "@components/shared/Title";
import usePlanets from "@hooks/usePlanets";
import useLootboxes from "@hooks/useLootboxes";
import useSpecialNfts from "@hooks/useSpecialNfts";

const Inventory = () => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isConnected } = useConnectWallet();
  const { planets, isLoading: isLoadingPlanets } = usePlanets();
  const { lootboxes, isLoading: isLoadingLootboxes } = useLootboxes();
  const { specialNfts, isLoading: isLoadingSpecialNfts } = useSpecialNfts();

  useEffect(() => {
    setIsLoading(isLoadingPlanets || isLoadingLootboxes || isLoadingSpecialNfts);
  }, [isLoadingPlanets, isLoadingLootboxes, isLoadingSpecialNfts]);

  useEffect(() => {
    if (!isLoading) {
      setAssets([...specialNfts, ...lootboxes, ...planets]);
    }
  }, [planets, lootboxes, specialNfts, isLoading])

  const filterOptions = {
    Rarity: ["Grade S+", "Grade A", "Grade B", "Grade C", "Grade D"],
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, minWidth: ['100%', "800px"], height: "100%", display: "flex" }}
    >
      <Box flex={1} position="relative">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>

          <Grid item xs={12} justifyContent="flex-start">
            <Title name="Inventory"/>
          </Grid>

          {!isConnected ? (
            <Grid item xs={12} justifyContent="flex-start">
              <Typography variant="h3" color={colors.paleRedColor}>Please Connect Wallet</Typography>
            </Grid>
          ) : (
            isLoading ? <CircularProgress sx={{ position: "absolute", top: "50vh", left: "50%" }} /> : (
              <Grid item xs={12} container spacing={4}>
                <ListInventory
                  assets={assets}
                  filterOptions={filterOptions}
                  hasExploreMore
                />
              </Grid>
           )
          )}

        </Grid>
      </Box>
    </Container>
  );
};
export default Inventory;
