const inventory = {
  "list.empty": "No items found :(",
  "property.rarity.label": "Rarity",
  "property.attack.label": "Attack",
  "property.defense.label": "Defense",
  "property.speed.label": "Speed",
  "property.awaken.label": "Awaken",
  "property.lucky.label": "Lucky",
  "property.rewardMulti.label": "Reward Mulitplier",
};
export default inventory;
