import NFTCard2 from "./NFTCard2";
import { NFTMeta } from "../../types/nftMeta";

type NFTCardProps = {
  item: NFTMeta
};

const NFTCard = ({ item }: NFTCardProps) => {
  const traitsDisplayOrder = ["Rarity"];

  const fakeMeta = {
    title: item.name,
    name: item.name,
    type: "",
    image_url: item.animation_url ? item.animation_url : item.image,
    description: item.description,
    attributes: item.attributes,
    isPlanet: item.isPlanet,
    isLootbox: item.isLootbox,
  }

  fakeMeta["tokenId"] = item["tokenId"];

  return (
    <NFTCard2 meta={fakeMeta} traitsDisplayOrder={traitsDisplayOrder} />
  );
};
export default NFTCard;
