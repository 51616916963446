import React from "react";
import { Box, Typography } from "@mui/material";
import ConnectWalletButton from "./ConnectWalletButton";
import { useTranslation } from "react-i18next";

const Greeting = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center">
      <Box flex={1} pr={1}>
        <Typography variant="h3" fontWeight="bold" sx={{textTransform: "uppercase"}}>{t("wallet:greeting")}</Typography>
      </Box>
      <ConnectWalletButton />
    </Box>
  );
};
export default Greeting;
