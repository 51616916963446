import wallet from "./wallet";
import navigation from "./navigation";
import inventory from "./inventory";
import notification from "./notification";
import vesting from "./vesting";
import staking from "./staking";
import dashboard from "./dashboard";
import lottery from "./lottery";
import raceGame from "./raceGame";
import planetDetail from "./planetDetail";
import planetReveal from "./planetReveal";
import machDetail from "./machDetail";

const en = {
  planetDetail,
  planetReveal,
  wallet,
  navigation,
  inventory,
  notification,
  vesting,
  staking,
  dashboard,
  lottery,
  raceGame,
  machDetail,
  common: {
    "unit.wars": "$WARS",
    "unit.stk": "$STK",
    "unit.gam": "$GAM",
  },
};

export default en;
