const Path = {
  Dashboard: {
    Root: "/",
  },
  Vesting: {
    Root: "/vesting",
  },
  Staking: {
    Root: "/staking",
  },
  Lottery: {
    Root: "/planet-nft-sale",
  },
  Marketplace: {
    Root: "/marketplace",
  },
  Inventory: {
    Root: "/inventory",
    Item: "/inventory/:contractAddress/:tokenId"
  },
  Setting: {
    Root: "/setting",
  },
  Fusion: {
    Root: "/fusion",
  },
  PlanetFi: {
    Root: "/planetFi"
  },
  PlanetFusion: {
    Root: "/planetFusion"
  },
  RaceGame: {
    Root: "/race-to-earn-game"
  },
  PlanetDetail: {
    Root: "/planet-detail",
    Item: "/planet-detail/:tokenId"
  },
  MachDetail: {
    Root: "/mach-detail",
    Item: "/mach-detail/:tokenId"
  },
  Verification: {
    Root: "/verification",
  },
};
export default Path;
