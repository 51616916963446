import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import TimelineIcon from "@mui/icons-material/Timeline";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink } from "react-router-dom";
import Path from "@routes/path";
import Tooltip from "@mui/material/Tooltip";
import AdbIcon from '@mui/icons-material/Adb';
import { colors } from '@utils/Constants'
import { useLocation } from 'react-router-dom'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoopIcon from '@mui/icons-material/Loop';
import { FaReact } from 'react-icons/fa';
import { IoMdPlanet } from 'react-icons/io'
import { Box } from "@mui/material";

const MENU_ITEMS = [
  {
    key: "dashboard",
    primary: "Dashboard",
    icon: <DashboardRoundedIcon />,
    to: Path.Dashboard.Root,
  },
  {
    key: "vesting",
    primary: "Vesting",
    icon: <TimelineIcon />,
    to: Path.Vesting.Root,
  },
  {
    key: "staking",
    primary: "Staking",
    icon: <StarRoundedIcon />,
    to: Path.Staking.Root,
  },
  {
    key: "lottery",
    primary: "Planet NFT Sale",
    icon: <IoMdPlanet fontSize={28} />,
    to: Path.Lottery.Root,
  },
  {
    key: "planetFi",
    primary: "PlanetFi",
    icon: <MonetizationOnIcon />,
    to: Path.PlanetFi.Root,
  },
  // {
  //   key: "planet Fusion",
  //   primary: "Planet Fusion",
  //   icon: <FaReact fontSize={28}/>,
  //   to: Path.Fusion.Root,
  // },
  // {
  //   key: "race-to-earn game",
  //   primary: "Race-to-Earn Game",
  //   icon: <Box
  //     sx={{
  //       background: `url(${process.env.PUBLIC_URL}/assets/race_game/icon_logo.png)`,
  //       backgroundSize: "100% 100%",
  //       width: "28px",
  //       height: "28px"
  //     }}
  //   />,
  //   to: Path.RaceGame.Root,
  // },
  {
    key: "marketplace",
    primary: "Marketplace",
    icon: <GroupIcon />,
  },
  {
    key: "inventory",
    primary: "Inventory",
    icon: <InventoryIcon />,
    to: Path.Inventory.Root,
  },
  // {
  //   key: "setting",
  //   primary: "Setting",
  //   icon: <SettingsIcon />,
  // },


];

const Menu = (props) => {
  const location = useLocation()

  return (
    <List
      sx={{
        marginTop: (theme) => theme.spacing(6),
      }}
    >
      {MENU_ITEMS.map(({ key, icon, to, primary }) => {
        return to ? (
          <ListItem
            button
            key={key}
            component={NavLink}
            to={to}
            sx={{
              color: location.pathname === to ? colors.orangeColor : colors.white,

            }}
            onClick={props.handleDrawerToggle}
            // activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon sx={{
              color: location.pathname === to ? colors.orangeColor : colors.white,

            }}>{icon}</ListItemIcon>
            <ListItemText sx={{
            }} primary={primary} />
          </ListItem>
        ) : (
          <Tooltip title="Coming Soon" placement="right" key={key}>
            <ListItem key={key} sx={{ opacity: 0.5, cursor: "default" }}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={primary} />
            </ListItem>
          </Tooltip>
        )
      }
      )}
    </List>
  );
};
export default Menu;
