import { useVesting } from "@contexts/VestingContext";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ClaimedAmount = () => {
  const { t } = useTranslation("vesting");
  const { amountClaimed } = useVesting();
  return (
    <Box flex={1}>
      <Typography variant="overline">{t("field.claimed.label")}</Typography>
      <Typography color="primary" fontFamily="Poppins">
        {amountClaimed}
      </Typography>
      <Typography variant="overline">{t("common:unit.wars")}</Typography>
    </Box>
  );
};
export default ClaimedAmount;
