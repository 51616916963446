import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useHistory } from "react-router-dom";
import LotteryFlipCard from "./LotteryFlipCard";
import { useTranslation } from "react-i18next";

const BorderIconButton = styled(IconButton)`
  border: 1px solid white;
`

const LotteryResult = (props) => {
  const { tokenIds, assets, onBack } = props;
  const [flippedCount, setFlippedCount] = useState(0);
  const history = useHistory();
  const { t } = useTranslation("lottery");


  useEffect(() => {
    let timeoutId;

    if (flippedCount < assets.length) {
      timeoutId = setTimeout(() => setFlippedCount(flippedCount + 1), 300);
    }

    return () => clearTimeout(timeoutId);
  }, [flippedCount, assets])

  const goToInventory = () => {
    history.push("/Inventory")
  };

  return (
    <Box position="relative" height="100%">
      <Grid container height="100%" p={2} flexDirection="column" rowGap={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <BorderIconButton onClick={onBack}>
              <ArrowBackIcon />
            </BorderIconButton>
          </Grid>
          <Grid item>
            <Typography variant="h1">{t("journey result")}</Typography>
          </Grid>
          <Grid item>
            <BorderIconButton onClick={goToInventory}>
              <InventoryIcon />
            </BorderIconButton>
          </Grid>
        </Grid>
        <Grid container item justifyContent={tokenIds.length === 1 ? "space-evenly" : "space-between"} textAlign="center" width="100%" height="18vw" m="0" pb={2} rowGap={2}>
          {assets.map((asset, i) => (
            <Box key={i} flexBasis="18%" height="50%">
              <LotteryFlipCard
                active={flippedCount > i}
                rarity={asset.attributes?.Rarity?.value}
                image_url={asset.image}
                video_url={asset.animation_url}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LotteryResult;