import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "@utils/Constants";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import UnfoundBalance from "@containers/lottery/UnfoundBalance";
import TotalSupply from "@containers/lottery/TotalSupply";
import UserBalance from "@containers/lottery/UserBalance";
import LotteryPreview from "@containers/lottery/LotteryPreview";
import LotteryResult from "./LotteryResult";
import LotteryDrawButton from "@containers/lottery/LotteryDrawButton";
import { useLottery } from "@contexts/LotteryContext";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import FadeInVideo from "@components/shared/FadeInVideo";

const MainPanel = styled(Box)`
  position: relative;
  background-color: rgba(235, 93, 128, 0.2);
  border: 2px solid ${colors.paleBrownColor};
  border-radius: 16px;
  overflow: hidden;
`

const InfoPanel = styled(Box)`
  border: 2px solid gray;
  border-radius: 16px;
  overflow: hidden;
`

const LotteryPanel = (props) => {
  const { t } = useTranslation("lottery");
  const { isChainIdCorrect, isConnected } = useConnectWallet();
  const { activeStep, drawnPlanets, submit } = useLottery();
  const [isPreview, setIsPreview] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const [isDrawDisabled, setIsDrawDisabled] = useState(false);
  const [isPlanetDrawn, setIsPlanetDrawn] = useState(false);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (activeStep === 0) {
      setIsDrawDisabled(false);
    } else if (activeStep === 1) {
      setIsDrawDisabled(true);
    } else if (activeStep === 2) {
      setIsVideo(true);
      setIsPreview(false);
    } else if (activeStep === 3) {
      setIsDrawDisabled(false);
    }
  }, [activeStep])

  useEffect(() => {
    const fetchData = async () => {
      const results = [];
      for (const tokenId of drawnPlanets) {
        const response = await fetch(`https://app.metawars.gg/planets/o/${tokenId}`);
        const result = await response.json();
        const traits = {};
        for (const attr of result.attributes) {
          traits[attr.trait_type] = attr;
        }
        result["attributes"] = traits;
        results.push(result);
      }
      setAssets(results);
      setIsVideo(false);
      setIsPlanetDrawn(true);
      setIsDrawDisabled(false);
    }

    if (drawnPlanets) {
      fetchData();
    }
  }, [drawnPlanets])

  const onBack = () => {
    setIsPreview(true);
  }

  return (
    <>
      <Grid container position="relative" spacing={2}>
        <Grid item xs={8}>
          <MainPanel position="relative">
            {isPreview ? (
              <>
                <LotteryPreview />
              </>
            ) : (isVideo ? (
              <>
                <FadeInVideo
                  src={`${process.env.PUBLIC_URL}/assets/planet_gacha_loading.mp4`}
                  width="100%"
                  height="100%"
                  autoPlay
                  loop
                />
                <Box width="100%" textAlign="center" pt={2} pb={2}>
                  <Typography variant="h2" fontWeight="bold" color={colors.paleRedColor}>{t("draw.waiting")}</Typography>
                </Box>
              </>
            ) : (
              <LotteryResult assets={assets} tokenIds={drawnPlanets} onBack={onBack} />
            ))}
            {activeStep < 2 || (activeStep === 3 && isPlanetDrawn && !isVideo) ? (
              <Grid container justifyContent="space-between" mt={2}>
                <Grid xs={12} position="relative">
                  <Box position="absolute">
                  <img width="100%" src={`${process.env.PUBLIC_URL}/assets/gacha_line.png`} />
                  </Box>
                </Grid>
                {[1, 10].map((quantity) => (
                  <Grid key={quantity} item xs={4}>
                    <LotteryDrawButton
                      quantity={quantity}
                      disabled={!isConnected || !isChainIdCorrect || isDrawDisabled}
                      onClick={() => {
                        setIsDrawDisabled(true);
                        setIsPlanetDrawn(false);
                        submit(quantity);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {!isConnected || !isChainIdCorrect || activeStep === 1 ? (
              <Box zIndex={3} position="absolute" top="0" bgcolor="rgba(0, 0, 0, 0.7)" width="100%" height="100%" />
            ) : null}

                {!isConnected || !isChainIdCorrect || activeStep === 1 ? (
                  <Box zIndex={4} position="absolute" width="100%" height="50%" top={0} bottom={0} mt="auto" mb="auto" textAlign="center">
                    <img src={`${process.env.PUBLIC_URL}/assets/warning.png`} />
                    {!isConnected ? (
                        <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>{t(`draw.connect`)}</Typography>
                    ) : (
                      isConnected && !isChainIdCorrect ? (
                          <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>{t(`draw.wrong_chain`)}</Typography>
                      ) : (
                        activeStep === 1 ? (
                            <Typography variant="h1" color={colors.orangeColor} position="relative" top="50%" sx={{ transform: "translateY(-150%)" }}>{t(`draw.step.${activeStep}`)}</Typography>
                        ) : null
                      )
                    )}
                  </Box>
                ) : null}
          </MainPanel>
        </Grid>
        <Grid container item xs={4} flexDirection="column">
          <InfoPanel p={2}>
            <Grid item>
              <Typography variant="h2" fontWeight="bold" color="#EB5D80">{t("information")}</Typography>
              <Typography paragraph>{t("description.1")}</Typography>
            </Grid>
            <Grid item>
              <TotalSupply />
              <UnfoundBalance />
              <UserBalance />
            </Grid>
          </InfoPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default LotteryPanel;