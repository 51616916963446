import React, { useState } from "react";
import { Box, Divider, Grid, Link, Paper, styled, Typography } from "@mui/material";

type StakingSwitchProps = {
  stakingStateIdx: number;
  setStakingStateIdx: (stakingStateIdx: number) => void;
  activeBgColor?: string;
  activeTextColor?: string;
  inactiveBgColor?: string;
  inactiveTextColor?: string;
}

const SwitchToggle = styled(Box)`
  border-radius: 20px;
  padding: 2px 8px;
  margin: 0px 2px;
  cursor: pointer;
  text-transform: uppercase;
`

const StakingSwitch = ({ 
  stakingStateIdx, setStakingStateIdx,
  activeBgColor = "#fe9812",
  activeTextColor = "#000",
  inactiveBgColor = "#ddd",
  inactiveTextColor = "#000",
} : StakingSwitchProps) => {

  return (
    <Box
      sx={{
        background: "#FFF",
        borderRadius: '20px',
        display: "flex",
        padding: "4px",
        cursor: "pointer"
      }}
    >
      <SwitchToggle
        sx={{
          background: stakingStateIdx === 0 ? activeBgColor : inactiveBgColor,
          color: stakingStateIdx === 0 ? activeTextColor : inactiveTextColor,
        }}
        onClick={() => setStakingStateIdx(0)}
      >
        Live
      </SwitchToggle>

      <SwitchToggle
        sx={{
          background: stakingStateIdx === 1 ? activeBgColor : inactiveBgColor,
          color: stakingStateIdx === 1 ? activeTextColor : inactiveTextColor,
        }}
        onClick={() => setStakingStateIdx(1)}
      >
        Finished
      </SwitchToggle>
    </Box>
  );
};
export default StakingSwitch;
