const machDetail = {
  title: "Mach Detail",
  attributes: "ATTRIBUTES",
  reconFailure: 'Recon Failure',
  notOwnerMsg: "You are not the owner of Mach #",
  share: "SHARE",
  nfTrade: "NFTRADE",
  reveal: "REVEAL",
  back: "Back",
  mechType: "Mech Type",
  mechName: "Mech Name",
  eye: "Eye Laser Color",
  colors: "Colors",
  pattern: "Pattern",
  background: "Background",
  exploitation: "Exploitation",
  bluePrint: "BluePrint",
};
export default machDetail;
