import { useEffect, useState } from "react";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import { map } from "lodash";
import axios from 'axios'

const useSpecialNfts = () => {
  const [specialNfts, setSpecialNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);

  const { address } = useConnectWallet();
  const { addError } = useErrorNotification();

  const refresh = () => {
    setIsLoading(true);
    setIsRefresh(!isRefresh)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const dataSourceUrl = `https://api.metawars.gg/nft/contracts/0x247a53162c8ef1b9ce4d2e17d96113b183ffc418/nftOwners/${address}/tokens`;
        let response = await fetch(dataSourceUrl);
        const result = await response.json();

        let promiseAllRes = await Promise.all(
          map(result, (e, idx) => {
            return axios.get(e.tokenUrl)
          })
        )

        for (let i = 0; i < result.length; i++) {
          result[i] = {
            ...result[i],
            ...promiseAllRes[i]['data']
          }
        }

        setSpecialNfts(result)
      } catch (err) {
        addError({ label: err.message });
      } finally {
        setIsLoading(false);
      }
    }

    if (!!address) {
      fetchData();
    } else {
      setSpecialNfts([])
      setIsLoading(false);
    }
  }, [address, isRefresh, addError]);

  return { specialNfts, isLoading, refresh };
};

export default useSpecialNfts;