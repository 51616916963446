import React, { createContext, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";

type ErrorProps = {
  addError: (e: ErrorMessage) => void;
};

export const ErrorNotificationContext = createContext<ErrorProps>({
  addError: () => {},
});

export const useErrorNotification = () => {
  const context = useContext(ErrorNotificationContext);
  return context;
};

type ErrorMessage = {
  label: React.ReactNode;
  timeout?: number;
};
export const ErrorNotificationProvider: React.FC = ({ children }) => {
  const [error, setError] = React.useState<ErrorMessage>();
  const addError = React.useCallback((error: ErrorMessage) => {
    setError((prev) => {
      const timeout = error.timeout || 3000;
      setTimeout(() => {
        setError(undefined);
      }, timeout);
      return error;
    });
  }, []);
  const value = React.useMemo(() => {
    return {
      error,
      addError,
    };
  }, [error, addError]);
  return (
    <ErrorNotificationContext.Provider value={value}>
      {error && (
        <Snackbar
          open
          autoHideDuration={error.timeout}
          onClose={() => setError(undefined)}
          message={error.label}
        />
      )}
      {children}
    </ErrorNotificationContext.Provider>
  );
};
