import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SocialMediaBanner = () => {
  const { t } = useTranslation("dashboard");
  return (
    <Box
      component={Paper}
      p={2}
      display="flex"
      flexDirection={["column", "row"]}
    >
      <Box flex={1}>
        <Typography>{t("social.description.1")}</Typography>
        <Typography color="textSecondary" variant="body2">
          {t("social.description.2")}
        </Typography>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          component="a"
          href="https://twitter.com/MetaWarsNFT"
          target="_blank"
        >
          {t("social.follow")}
        </Button>
      </Box>
    </Box>
  );
};
export default SocialMediaBanner;
