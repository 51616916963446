export const colors = {
    white: "rgb(250, 250, 250)",
    paleWhite: "rgb(164, 164, 164)",
    purpleColor: "rgb(255,0,255)",
    palePurpleColor: "rgba(255, 0, 255, 0.8)",
    palePurpleClearColor: "rgba(255, 0, 255, 0.2)",
    palePurpleHoverColor: "rgba(255, 0, 255, 0.6)",
    brownColor: "rgb(255,137,57)",
    paleBrownColor: "rgba(255, 137, 57, 0.8)",
    paleBrownClearColor: "rgba(255, 137, 57, 0.2)",
    paleBrownHoverColor: "rgba(255, 137, 57, 0.6)",
    blueColor: "rgb(0,255,255)",
    paleBlueColor: "rgba(0, 255, 255, 0.8)",
    paleBlueClearColor: "rgba(0, 255, 255, 0.2)",
    paleBlueHoverColor: "rgba(0, 255, 255, 0.6)",
    paleRedColor: "#ff4c83",
    orangeColor: "#ff8939",
    gray: "#626262",
    twitterColor: "#55adee",
    discordColor: "#8c9eff",
    menuBgColor: "rgba(0, 0, 0, 0.4)",
    transparentBgColor: "rgba(0, 0, 0, 0.2)",
    orangeRedGradientColor: "linear-gradient(90deg, #ff8939 0%, #ff4c83 100%)",
    lightBlueColor: "#00ffff",
    violetColor: "#ff00cc",
    lightPurpleColor: "#d982ff",
    dimGray: `rgb(100,100,100, 0.8)`,
    twitterBlue: "#1d9bf0",
    twitterHoverBlue: "#0c7abf",
}

export const FUSION_COST = 300;
export const PLANET_POWER = {
    "Grade S+": 9999999,
    "Grade A": 100000,
    "Grade B": 10000,
    "Grade C": 1000,
    "Grade D": 100,

}