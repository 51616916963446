import { useEffect, useRef } from "react";

type FadeInVideoProps = {
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  width?: string;
  height?: string;
  style?: object;
};

const FadeInVideo = (props: FadeInVideoProps) => {
  const { src, autoPlay, loop, width, height, style } = props;
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    videoRef.current.style.opacity = "1";
  }, [])
  return (
    <video
      ref={videoRef}
      src={src}
      autoPlay={autoPlay}
      loop={loop}
      width={width}
      height={height}
      style={{
        opacity: 0,
        transition: "opacity 5s",
        ...style
      }}
    />
  )
};

export default FadeInVideo;