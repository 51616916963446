import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import EmptyBlock from "@components/nft/EmptyBlock";
import PaginatedCardList from "@components/nft/PaginatedCardList";
import CardFilter from "@components/nft/CardFilter";
import "@components/nft/CardList.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';
type ListInventoryProps = {
  assets: any[];
  filterOptions?: any;
  selectedItems?: any[];
  onSelect?: (item: any) => void;
  hasExploreMore?: boolean;
}

type Filter = {
  [key: string]: {}
}

const ListInventory = (props: ListInventoryProps) => {
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState<Filter>({});
  const [page, setPage] = useState(0);  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { assets, filterOptions = null, selectedItems, onSelect, hasExploreMore = false } = props;

  const onFilterChange = (key, option, checked) => {
    setFilter({
      ...filter,
      ...{
        [key]: {
          ...filter[key],
          filterChecked: (filter[key]?.["filterChecked"] || 0) + (checked ? 1 : -1),
          [option]: checked
        }
      }
    })
  }

  useEffect(() => {
    setPage(0);
  }, [searchText, filter])

  const filteredAssets = useMemo(() => {
    if (!assets) return [];

    return assets.filter((asset) => {
      let match = asset.name.toLowerCase().includes(searchText.toLowerCase());
      if (!match) return false;

      for (const [key, options] of Object.entries(filter)) {
        const traits = asset.attributes || asset.properties || asset.traits;
        if (!options?.["filterChecked"]) {
          continue;
        }
        // const trait = traits.find((element) => element["trait_type"] === key)
        const trait = traits[key];
        if (!trait) return false;

        match = options[trait["value"]];
        if (!match) return false;
      }

      return true;
    })
  }, [assets, searchText, filter]);

  return (
    <Grid item xs={12}>
      {filterOptions ? (
        <Grid container justifyContent="end">
          <CardFilter
            searchText={searchText}
            onSearchChange={(e) => setSearchText(e.target.value)}
            filters={filterOptions}
            onFilterChange={onFilterChange}
            isMobile={isMobile}
          />
        </Grid>
      ) : null}
      <PaginatedCardList page={page} isMobile={isMobile} setPage={setPage} selectedCards={selectedItems} onSelect={onSelect} assets={filteredAssets} hasExploreMore={hasExploreMore} />
    </Grid>
  );
};
export default ListInventory;
