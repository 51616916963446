import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import WalletRequiredPrompt from "../../containers/wallet/WalletRequiredPrompt";
import Menu from "./Menu";
import LogoLink from "./LogoLink";
import { colors } from '@utils/Constants'
const drawerWidth = 320;

const DesktopFrame: React.FC = ({ children }) => {
  return (
    <Box sx={{ display: "flex", height: "100vh",
      backgroundColor: colors.transparentBgColor,
    }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            padding: (theme) => theme.spacing(4),
            width: drawerWidth,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.menuBgColor,

          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box flex={1}
          sx={{
          }}
        >
          <Box px={2}>
            <LogoLink />
          </Box>

          <Menu />
        </Box>
        <Box>
          <WalletRequiredPrompt />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, overflow: "auto", p: 4 }}>
        <Box pb={4}>{children}</Box>
      </Box>
    </Box>
  );
};
export default DesktopFrame;
