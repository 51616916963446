import { useVesting } from "@contexts/VestingContext";
import { Button } from "@mui/material";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

const ClaimVesting = () => {
  const { t } = useTranslation("vesting");
  const { isConnected } = useConnectWallet();
  const { claimVestedTokens, hasVesting, disableClaim } = useVesting();
  if (!isConnected || !hasVesting) return null;
  return (
    <Formik onSubmit={claimVestedTokens} initialValues={{}}>
      {({ isSubmitting }) => (
        <Form>
          <Button
            color="info"
            type="submit"
            variant="contained"
            disabled={disableClaim || isSubmitting}
          >
            {isSubmitting
              ? t("action.claim.loading")
              : t("action.claim.default")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default ClaimVesting;
