import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const FlipCardContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  &.active {
    transform: rotateY(180deg);
  }
`

const FlipCard = styled(Box)`
  width: 100%;
  height: 100%;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(0deg);
`

const FlipCardFront = styled(FlipCard)`
`

const FlipCardBack = styled(FlipCard)`
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  background-color: unset;
`

type LotteryFlipCardProps = {
  active: boolean,
  rarity: string,
  image_url: string,
  video_url?: string,
};

const LotteryFlipCard = (props: LotteryFlipCardProps) => {
  const { active, rarity = "", image_url, video_url } = props;

  return (
    <FlipCardContainer className={active ? "active" : ""}>
      <FlipCardFront>
        <img src={`${process.env.PUBLIC_URL}/assets/rarity_border/card_back.png`} width="100%" />
      </FlipCardFront>
      <FlipCardBack>
        {video_url ? (
          <video
            src={video_url}
            autoPlay
            loop
            width="80%"
            height="80%"
            style={{ position: "absolute", top: "10%", left: "10%", objectFit: "contain" }}
          />
        ) : (
          <img
            src={image_url}
            width="92%"
            height="92%"
            style={{ position: "absolute", top: "7%", left: "4%", objectFit: "contain" }}
          />
        )}
        {rarity ? (
          <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/assets/rarity_border/rarity_${rarity.replace(" ", "_")}.png`} style={{ objectFit: "contain", position: "absolute", top: 0, left: 0 }} />
        ) : null}
      </FlipCardBack>
    </FlipCardContainer>
  )
};

export default LotteryFlipCard;