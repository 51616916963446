import React from "react";
import {
  Button,
  Dialog,
  Box,
  DialogProps as MuiDialogProps,
  DialogContent,
  Typography,
  TextField,
  Paper as MuiPaper,
  styled,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Field, Form } from "formik";
import { object, number } from "yup";
import { useTranslation } from "react-i18next";
import { StakingPool } from "../../types/staking";
import { useStaking } from "@contexts/StakingContext";
import DataField from "@components/shared/DataField";

const initialValues = {
  amount: 0,
};
const stakingSchema = (tokenBalance = 0) =>
  object({
    amount: number().min(0).max(tokenBalance).required(),
  });
const Paper = styled(MuiPaper)(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[10],
}));

type StakingDialogProps = MuiDialogProps & {
  handleClose: () => void;
  pool: StakingPool;
  onSubmit: ({ amount: number }) => void;
};

const StakeDialogForm: React.FC<StakingDialogProps> = ({
  pool,
  handleClose,
  onSubmit,
  ...props
}) => {
  const { [pool]: stakingPool } = useStaking();
  const { t } = useTranslation("staking");
  const handleSubmit = React.useCallback(
    async (values, { resetForm }) => {
      await onSubmit(values);
      resetForm();
    },
    [onSubmit]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={() => stakingSchema(stakingPool?.values?.tokenBalance)}
    >
      {({ isValid, setValues }) => (
        <Dialog maxWidth="xs" fullWidth PaperComponent={Paper} {...props}>
          <DialogContent>
            <Form>
              <Box p={3}>
                <Box display="flex" alignItems="center" mb={3}>
                  <Typography variant="h1" color="primary" sx={{ flex: 1 }}>
                    {t("form.stake.amount")}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box mb={2}>
                    <DataField
                      label="Your Balance"
                      value={stakingPool?.values.tokenBalance || 0}
                    />
                  </Box>
                  <Field name="amount">
                    {({ field, meta, form: { setFieldValue } }) => {
                      const error =
                        field.value > stakingPool?.values.tokenBalance;
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Field
                            fullWidth
                            as={TextField}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: "any",
                              },
                            }}
                            name="amount"
                            type="number"
                            label={stakingPool?.values.stakedTokenLabel || ""}
                            helperText={error && "Insufficient Balance"}
                            error={!!meta.error || !!error}
                          />
                          <Button
                            sx={{ marginLeft: 1 }}
                            disabled={!stakingPool?.values.tokenBalance}
                            onClick={() => {
                              if (stakingPool?.values.tokenBalance > 0) {
                                setFieldValue(
                                  "amount",
                                  stakingPool?.values.tokenBalance
                                );
                              }
                            }}
                          >
                            Max
                          </Button>
                        </Box>
                      );
                    }}
                  </Field>
                  <Box mt={4} textAlign="center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid}
                    >
                      {t("form.stake.confirm")}
                    </Button>
                    <br />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setValues(initialValues);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};
export default StakeDialogForm;
