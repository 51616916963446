import { useCallback } from "react";
import Greeting from "../containers/wallet/Greeting";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import Title from "@components/shared/Title";
import RaceGameContainer from "@containers/raceGame/RaceGameContainer";
import { RaceGameProvider } from "@contexts/RaceGameContext";

const RaceGame = () => {
  const { t } = useTranslation("raceGame");
  const { isConnected } = useConnectWallet();

  return (
    <>
      <Container  sx={{ marginLeft: 0, maxWidth: "1400px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" position="relative">
            <Title name={t("title")} />
          </Grid>

        </Grid>
      </Container>
      <Container maxWidth="lg" sx={{ ml: 0, minWidth: "800px", height: "800px" }}>
        <RaceGameProvider>
          <RaceGameContainer />
        </RaceGameProvider>
      </Container>
    </>
  );
};
export default RaceGame;
