import { useEffect, useState } from "react";
import { ContractName, useContracts } from "@contexts/ContractsContext";
import { useStaking } from "@contexts/StakingContext";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StakingPool } from "../../types/staking";
import { numberDisplay } from "../../utils/valueDisplayHelper";
import { ethers } from "ethers";
import { useConnectWallet } from "@contexts/ConnectWalletContext";

const RewardsOverview = () => {
  const { t } = useTranslation("staking");
  const { t: commonT } = useTranslation("common");
  const { address } = useConnectWallet();
  const staking = useStaking();
  const contracts = useContracts();

  const [warsBalance, setWarsBalance] = useState(0);

  let total = 0;
  let stakedLiquidity = 0;
  let reward = 0;
  const wars = contracts[ContractName.WARSToken];

  useEffect(() => {
    (async () => {
      let balance = await wars.balanceOf(address);
      balance = ethers.utils.formatEther(balance);
      balance = parseFloat(balance)
      setWarsBalance(balance);
    })()
  }, [address, wars]);

  for (const pool of Object.values(StakingPool)) {
    const { [pool]: stakingPool } = staking;
    const { values } = stakingPool || {};
    if (!!values) {
      total += parseFloat(values.totalValueLocked.toString());
      stakedLiquidity += parseFloat(values.amountStaked.toString());
      reward += parseFloat(values.pendingReward.toString());
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item>
        <Typography pb={1}>{t("field.totalStakingLiquidity.label")}</Typography>
        <Typography pb={1} variant="h3" fontWeight="bold" color="primary">{`${numberDisplay(total)}`}</Typography>
        <Typography>{commonT("unit.wars")}</Typography>
      </Grid>
      <Grid item ml='auto'>
        <Typography pb={1}>{t("field.warsBalance.label")}</Typography>
        <Typography pb={1} variant="h3" fontWeight="bold" color="primary">{numberDisplay(warsBalance)}</Typography>
        <Typography>{commonT("unit.wars")}</Typography>
      </Grid>
    </Grid>
  )
};
export default RewardsOverview;
