import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  border: 2px solid gray;
  border-radius: 16px;
  position: relative;
  text-align: center;
  background-image: url(${process.env.PUBLIC_URL}/assets/race_game/bg.png);
  background-size: 1800px;
  background-position-y: -175px;
  transition: background-position-x 0.2s;
`;

type RaceGameDisplayContainerProps = {
  counter: number;
  max: number;
  milestones: number[];
  addCounter: () => void;
};

let timeoutId;

const RaceGameDisplayContainer = (props: RaceGameDisplayContainerProps) => {
  const { counter, max, milestones = [], addCounter } = props;
  const [isShowSmoke, setIsShowSmoke] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      setIsShowSmoke(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsShowSmoke(false), 200);
    }
  }, [counter]);

  return (
    <Container sx={{ backgroundPositionX: `${-counter * 5}px`, overflow: "hidden" }}>
      <Box style={{ position: "absolute", top: "145px", left: "25px" }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/race_game/icon_car_smoke.png`}
          height="50px"
          style={{ position: "relative", opacity: isShowSmoke ? 1 : 0 }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/race_game/icon_car.png`}
          height="50px"
          style={{ position: "relative", zIndex: 2 }}
        />
        {milestones.map((milestone) => (
          <Box key={milestone} position="absolute" top="0px" left={`${140 + (milestone - counter) * 5}px`} sx={{ opacity: counter >= milestone ? 0 : 1, transition: "left 0.2s" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/race_game/icon_coin.png`}
              height="50px"
            />
            <Typography fontSize="25px" fontWeight="bold" lineHeight="1.2">20 CRACE</Typography>
          </Box>
        ))}
        <Box position="absolute" top="2px" left={`${70 + (max - counter) * 5}px`}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/race_game/icon_goal.png`}
            height="50px"
          />
          <Typography fontSize="25px" fontWeight="bold">GOAL</Typography>
          <img
            src={`${process.env.PUBLIC_URL}/assets/race_game/icon_flag.png`}
            height="60px"
            style={{ position: "relative", top: "-135px", left: "15px" }}
          />
        </Box>
      </Box>
      <Box position="absolute" left="0" bottom="-50px">
        <img
          src={`${process.env.PUBLIC_URL}/assets/race_game/bg_mask.png`}
          width="100%"
          style={{ verticalAlign: "bottom" }}
        />
        <Box onClick={addCounter} position="absolute" left="50%" top="45%" sx={{ transform: "translate(-50%, -25%)", cursor: "pointer" }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/race_game/btn_race.png`}
            height="120px"
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/race_game/btn_race_gif.gif`}
            height="120px"
            style={{ position: "absolute", left: "45px", top: "-20px" }}
          />
        </Box>
      </Box>
      <Box>
      </Box>
    </Container>
  );
};

export default RaceGameDisplayContainer;