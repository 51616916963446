import { Box, styled } from "@mui/material";

export default styled(Box)({
  borderRadius: 16,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundImage: `url("./assets/WARS_staking.png")`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center"
});
