const vesting = {
  title: "Vesting and Claiming",
  description:
    "This claiming and vesting panel is for private sale participants. Once you’ve connected your BSC wallet the number of claimable tokens and vesting schedule will be shown below.All vesting schedules are daily linear where you can claim at your will. Tokens will NOT be automatically distributed, you must claim them.",
  "overview.title": "Account Overview",
  "wars.current_price": "Current $WARS token price {{price}}",
  "field.claimed.label": "Claimed",
  "field.avialable_to_claim.label": "Available to Claim",
  "action.claim.default": "Claim",
  "action.claim.loading": "Processing Claim",
};
export default vesting;
