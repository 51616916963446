import { useEffect, useState } from "react";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import { map } from "lodash";
import axios from 'axios'

const usePlanets = () => {
  const [planets, setPlanets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);

  const { address, signer } = useConnectWallet();
  const { addError } = useErrorNotification();

  const refresh = () => {
    setIsLoading(true);
    setIsRefresh(!isRefresh)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const dataSourceUrl = `https://api.metawars.gg/nft/contracts/0x1f099dafba907498e701a72da0007d58f88459de/nftOwners/${address}/tokens`;
        let response = await fetch(dataSourceUrl);
        const result = await response.json();

        let promiseAllRes = await Promise.all(
          map(result, (e, idx) => {
            return axios.get(e.tokenUrl)
          })
        )

        for(let i = 0; i < result.length; i++){
          result[i] = {
            ...result[i],
            ...promiseAllRes[i]['data'],
            isPlanet: true
          }
        }

        setPlanets(result)
      } catch (err) {
        addError({ label: err.message });
      } finally {
        setIsLoading(false);
      }
    }

    if (!!address) {
      fetchData();
    } else {
      setPlanets([])
      setIsLoading(false);
    }
  }, [address, isRefresh]);

  return { planets, isLoading, refresh };
};

export default usePlanets;