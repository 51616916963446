import React from "react";
import { Box, ButtonBase, Card, Grid, GridSize, IconButton, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import NFTCard from "./NFTCard";
import { NFTMeta } from "../../types/nftMeta";
import ExploreMoreCard from "./ExploreMoreCard";
import { colors } from '@utils/Constants'
import axios from "axios";
const Link = styled((props: TypographyProps) => (
  <Typography variant="h4" fontWeight="bold" component="span" {...props} />
))`
  color: ${colors.paleRedColor};
  text-decoration: underline;
  cursor: pointer;
  font-family: Jura;
`;

const PaginatedCardList = ({
  page,
  setPage,
  assets,
  itemsPerRow = 4,
  onSelect,
  selectedCards = [],
  hasExploreMore,
  isMobile
}: {
  page: number;
  setPage: (page: any) => void;
  assets: NFTMeta[];
  itemsPerRow?: number;
  onSelect?: (item: any) => void;
  selectedCards?: number[];
  hasExploreMore?: boolean;
  isMobile?: boolean;
}) => {
  const history = useHistory();
  const [pageSize, maxPage, placeholderSize, gridSize] = React.useMemo(() => {
    const size = itemsPerRow * 2;
    const placeholderGrid = 12 % itemsPerRow;
    return [
      size,
      Math.ceil(assets.length / size),
      Math.ceil(placeholderGrid / 2) as GridSize,
      Math.ceil((12 - placeholderGrid) / itemsPerRow) as GridSize,
    ];
  }, [assets, itemsPerRow]);

  const items = React.useMemo(() => {
    const list = assets.slice(pageSize * page, (page + 1) * pageSize);

    return list; //[list.slice(0, itemsPerRow), list.slice(itemsPerRow)];
  }, [page, assets, itemsPerRow, pageSize]);


  const [disabledPrev, handlePrev] = React.useMemo(() => {
    const updatedPage = page - 1;
    const disabled = updatedPage < 0;
    return [disabled, () => (!disabled ? setPage((prev) => prev - 1) : null)];
  }, [page]);

  const [disabledNext, handleNext] = React.useMemo(() => {
    const updatedPage = page + 1;
    const disable = updatedPage >= maxPage;
    return [disable, () => (!disable ? setPage((prev) => prev + 1) : null)];
  }, [page, maxPage]);

  const handlePlanetRaffleButton = () => {
    history.push("/planet-nft-sale");
  };

  const handleMarketplaceButton = () => {
    window.open("https://nftrade.com/assets/bsc/0x1f099dafba907498e701a72da0007d58f88459de");
  };

  // console.log('items', items)

  return (
    <Box>
      <Grid container item xs={12} justifyContent={isMobile ? "flex-end" : "space-between"}>
        <Grid item>
          <Typography variant="h4" sx={{ fontFamily: "Jura" }}>
            Explore More: <Link sx={{ margin: 'auto 10px' }} onClick={handlePlanetRaffleButton}>PLANET RAFFLE</Link> OR <Link sx={{ marginLeft: '10px' }} onClick={handleMarketplaceButton}>MARKETPLACE</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Box textAlign="right" mb={2}>
            <IconButton size="large" onClick={handlePrev} disabled={disabledPrev}>
              <ArrowBackIosNew />
            </IconButton>
            <Typography display="inline">{page + 1} / {maxPage}</Typography>
            <IconButton size="large" onClick={handleNext} disabled={disabledNext}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        {items.map((item) => {
          const tokenId = Number(item["tokenId"]);
          const contractAddress = item["contractAddress"]
          return (
            <Grid key={tokenId} item xs={isMobile ? 6 : gridSize}>
              {onSelect ? (
                <Box
                  onClick={() => onSelect(item)}
                  sx={{
                    cursor: "pointer",
                    border: selectedCards.includes(tokenId) ? "8px solid gold" : "transparent",
                    borderRadius: "20px",
                    backgroundColor: selectedCards.includes(tokenId) ? "gold" : "transparent"
                  }}
                >
                  <NFTCard item={item} />
                </Box>
              ) : (
                <NFTCard item={item} />
              )}
            </Grid>
          )
        })}
        {hasExploreMore && ((page + 1) === maxPage || maxPage <= 1) ? (
          <Grid item xs={isMobile ? 6 : gridSize} alignSelf="stretch">
            <ExploreMoreCard />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
export default PaginatedCardList;
