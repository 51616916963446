import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import Greeting from "@containers/wallet/Greeting";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import ItemDetails from "@components/nft/ItemDetails"

const InventoryItem = (props) => {
  const match = useRouteMatch();
  const contractAddress = useMemo(() => match.params["contractAddress"], [match]);
  const tokenId = useMemo(() => match.params["tokenId"], [match]);

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, minWidth: "800px", height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item xs={12} pb={4}>
            <Typography variant="h1" color="primary" fontWeight="bold" pb={1}>
              Inventory Item
            </Typography>
            <Divider sx={{ width: "120px", borderBottom: "2px solid #408bb4" }} />
          </Grid>

          <Grid item xs={12} container spacing={4}>
            <ItemDetails contractAddress={contractAddress} tokenId={tokenId} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default InventoryItem;