import React, { createContext, useContext, useReducer } from "react";
import { useConnectWallet } from "./ConnectWalletContext";
import { useContracts, ContractName } from "./ContractsContext";

type InventoryStateProps = {
  tokens?: number[];
};
export interface InventoryProps extends InventoryStateProps {
  getTokenURI?: (v: string) => void;
}

export const InventoryContext = createContext<InventoryProps>({});

export const useInventory = () => {
  const context = useContext(InventoryContext);
  return context;
};

const initialState = {};

type Action = {
  type: "init";
  tokens?: number[];
};

const reducer: React.Reducer<InventoryStateProps, Action> = (
  state,
  { type, ...actionProps }
) => {
  switch (type) {
    case "init":
      return actionProps;
    default: {
      return state;
    }
  }
};

export const InventoryProvider: React.FC = ({ children }) => {
  const { address } = useConnectWallet();
  const { [ContractName.NFT]: nftContract } = useContracts();
  const [state, dispatch] = useReducer(reducer, initialState);

  const init = React.useCallback(async () => {
    if (nftContract && address) {
      try {
        const tokens = await nftContract.getTokensByOwner(address);
        dispatch({
          type: "init",
          tokens: tokens?.map((token) => token.toNumber()),
        });
      } catch (e) {
        console.log(e);
        // send error log
      }
    }
  }, [address, nftContract]);

  const getTokenURI = React.useCallback(
    async (tokenId) => {
      if (nftContract && address) {
        try {
          const tokenURI = await nftContract.tokenURI(tokenId);
          return tokenURI;
        } catch (e) {
          console.log(e);
          // send error log
        }
      }
    },
    [address, nftContract]
  );
  React.useEffect(() => {
    init();
  }, [init]);

  const value = React.useMemo(() => {
    return { ...state, getTokenURI };
  }, [state, getTokenURI]);
  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  );
};
