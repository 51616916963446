import React from "react";
import { Box, Typography } from "@mui/material";
import { useVesting } from "@contexts/VestingContext";

const steps = new Array(10).fill("").map((_, idx) => `${idx * 10}%`);

const VestingProgress = () => {
  const { progress } = useVesting();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={1}>
        <Box
          sx={{
            borderRadius: 15,
            bgcolor: "primary.main",
            height: 15,
            width: progress > 0 ? `calc(${progress}% + 7.5px)` : 0,
            textAlign: "right",
            transition: "0.1s ease-out",
          }}
          position="relative"
        >
          <Box position="absolute" sx={{ right: -16, top: -6 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/planet.png`}
              width={35}
              alt={`${progress}%`}
              title={`${progress}%`}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" position="relative">
        {steps.map((step, idx) => (
          <React.Fragment key={step}>
            <Box
              sx={{
                flex: 1,
                ml: "-10px",
              }}
              key={step}
            >
              <Typography
                sx={{
                  width: 40,
                  textAlign: "center",
                }}
              >
                {step}
              </Typography>
            </Box>
            {idx === steps.length - 1 && (
              <Typography sx={{ position: "absolute", right: -12 }}>
                100%
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
export default VestingProgress;
