import React from "react";
import Greeting from "@containers/wallet/Greeting";
import { Box, Container, Grid, Typography } from "@mui/material";
import SocialMediaBanner from "@components/shared/SocialMediaBanner";
import AvailableToClaim from "@containers/vesting/AvailableToClaim";
import ClaimedAmount from "@containers/vesting/ClaimedAmount";
import ClaimVesting from "@containers/vesting/ClaimVesting";
import VestingProgress from "@containers/vesting/VestingProgress";
import { useTranslation } from "react-i18next";
import Title from "@components/shared/Title";

const Vesting = () => {
  const { t } = useTranslation("vesting");
  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          
          <Grid item xs={12} justifyContent="flex-start">
            <Title name={t("title")}/>
            <Typography>{t("description")}</Typography>
          </Grid>
         
          <Grid item xs={12}>
            <Typography variant="h3" color="primary" gutterBottom>
              {t("overview.title")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ClaimedAmount />
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" alignItems="center">
                  <AvailableToClaim />
                  <ClaimVesting />
                </Box>
                <Typography variant="overline">
                  {t("common:unit.wars")} |{" "}
                  {t("wars.current_price", { price: "N/A" })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <VestingProgress />
          </Grid>
          <Grid item xs={12}>
            <SocialMediaBanner />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Vesting;
