import React from "react";
import { Button, ButtonProps } from "@mui/material";
import {
  StakingActionType,
  StakingActiveEvents,
} from "@contexts/StakingContext";
import { useTranslation } from "react-i18next";

type PoolCardButtonProps = {
  type: StakingActionType;
  activeEvents?: StakingActiveEvents;
} & Omit<ButtonProps, "type">;

const PoolCardButton = ({
  sx,
  type,
  activeEvents,
  disabled: disabledProp,
  ...buttonProps
}: PoolCardButtonProps) => {
  const { t } = useTranslation("staking");
  const [disabled, isActive, eventLabel] = React.useMemo(() => {
    const activeEvent = activeEvents?.events?.find((e) => e.active);
    const isActionActive = !!activeEvent && activeEvents.actionType === type;
    return [
      !!activeEvent || disabledProp,
      activeEvents?.actionType === type,
      (isActionActive && activeEvent?.type) || "default",
    ];
  }, [type, activeEvents, disabledProp]);
  return (
    <Button
      color="info"
      variant="contained"
      sx={{
        minWidth: "124px",
        width: "124px",
        height: "32px",
        alignSelf: "center",
        fontSize: "16px",
        padding: "18px 20px",
        fontWeight: "bold",
        ...sx
      }}
      disabled={disabled}
      {...buttonProps}
    >
      {t(`action.${type}.${eventLabel}`)}
    </Button>
  );
};
export default PoolCardButton;
