import { providers, Signer } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";

export type Address = `0x${string}`;

const providerOptions: IProviderOptions = {};

export interface ConnectWalletProps {
  web3Modal?: Web3Modal;
  provider?: providers.Web3Provider;
  signer?: Signer;
  block?: number;
  address?: Address;
  chainId?: number;
  isConnected: boolean;
  connect?: () => Promise<void>;
  disconnect?: () => Promise<void>;
  isChainIdCorrect?: boolean;
}

export const ConnectWalletContext = createContext<ConnectWalletProps>({
  isConnected: false,
});

export const useConnectWallet = () => {
  const context = useContext(ConnectWalletContext);

  return context;
};

const getHexademicalChainId = (chainId: number) => `0x${chainId.toString(16)}`;

const defaultDecimalChainId = parseInt(process.env.REACT_APP_CHAIN_ID || "0");

const isChainIdCorrect = (chainId: number) => {
  if (!defaultDecimalChainId) return true;
  return (
    getHexademicalChainId(chainId) ===
    getHexademicalChainId(defaultDecimalChainId)
  );
};

export const ConnectWalletProvider: React.FC = ({ children }) => {
  const { addError } = useErrorNotification();
  const [web3Modal, setWeb3Modal] = useState<Web3Modal>();
  const [provider, setProvider] = useState<providers.Web3Provider>();
  const [signer, setSigner] = useState<Signer>();
  const [block, setBlock] = useState<number>();
  const [address, setAddress] = useState<Address>();
  const [isConnected, setIsConnected] = useState(false);
  const [chainId, setChainId] = useState<number>();

  const disconnect = React.useCallback(async () => {
    web3Modal?.clearCachedProvider();
    setSigner!(undefined);
    setChainId!(undefined);
    setAddress!(undefined);
    setProvider!(undefined);
    setIsConnected!(false);
  }, [web3Modal]);

  const connect = React.useCallback(async () => {
    if (web3Modal) {
      try {
        const myModalProvider = await web3Modal.connect();
        const ethersProvider = new providers.Web3Provider(
          myModalProvider,
          "any"
        );
        const ethersSigner = ethersProvider.getSigner();
        const ethAddress = (await ethersSigner.getAddress()) as Address;
        const network = await ethersProvider.getNetwork();

        if (!isChainIdCorrect(network.chainId) && defaultDecimalChainId) {
          disconnect();
          addError({ label: "Please switch network" });
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: getHexademicalChainId(defaultDecimalChainId),
                },
              ],
            });
          } catch (error) {
            if (error.code === 4902) {
              // todo: wallet_addEthereumChain
            }
            return;
          }
        }

        setSigner!(ethersSigner);
        setAddress!(ethAddress);
        // setAddress!("0xF4b9E164A61bF9FabdaE21Ae1AD27dD035d6Db3D");
        setChainId!(network.chainId);
        setProvider!(ethersProvider);
        setIsConnected!(true);
      } catch (ex) { }
    }
  }, [web3Modal, addError, disconnect]);

  useEffect(() => {
    if (isConnected) {
      return;
    }

    const myWeb3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(myWeb3Modal);
  }, [isConnected, setWeb3Modal]);

  useEffect(() => {
    if (!web3Modal || !web3Modal.cachedProvider) {
      return;
    }

    async function init() {
      await connect();
    }

    init();
  }, [web3Modal, setBlock, connect]);

  // listen new block
  useEffect(() => {
    if (!provider || !setBlock) {
      return undefined;
    }

    function handleNewBlock(blockNumber: number) {
      setBlock(blockNumber);
    }

    provider.on("block", handleNewBlock);

    return () => {
      provider.removeListener("block", handleNewBlock);
    };
  }, [provider, setBlock]);

  // event tracking
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts: string[]) => {
        if (isConnected) {
          disconnect();
          connect();
        } else {
          disconnect();
        }
      });

      window.ethereum.on("chainChanged", (updatedChainId: string) => {
        if (isConnected) {
          connect();
        } else {
          disconnect();
        }
      });

      window.ethereum.on("disconnect", () => {
        disconnect();
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners();
      }
    };
  }, [connect, disconnect, provider, isConnected]);

  return (
    <ConnectWalletContext.Provider
      value={{
        web3Modal,
        address,
        block,
        isConnected,
        chainId,
        signer,
        provider,
        connect,
        disconnect,
        isChainIdCorrect: chainId && isChainIdCorrect(chainId),
      }}
    >
      {children}
    </ConnectWalletContext.Provider>
  );
};
