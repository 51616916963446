import React, { useCallback, useEffect, useState } from "react";
import Greeting from "@containers/wallet/Greeting";
import { styled, Box, Button, Container, Grid, Typography, Skeleton, Link } from "@mui/material";
import SocialMediaBanner from "@components/shared/SocialMediaBanner";
import AvailableToClaim from "@containers/vesting/AvailableToClaim";
import ClaimedAmount from "@containers/vesting/ClaimedAmount";
import ClaimVesting from "@containers/vesting/ClaimVesting";
import VestingProgress from "@containers/vesting/VestingProgress";
import { useTranslation } from "react-i18next";
import Title from "@components/shared/Title";
import FusionImg from '../images/fusion800.png'
import { colors } from "@utils/Constants";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import Path from "@routes/path";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material';
import usePlanets from "@hooks/usePlanets";
import useLootboxes from "@hooks/useLootboxes";

const StyledActionButton = styled(Button)`
  color: ${colors.white};
  border-radius: 20px;
  border: 1px solid ${colors.paleWhite};
  text-transform: unset;
  width: 160px;
  &:hover{
    opacity: 0.7;
  }
  @media(max-width: 1248px) {
    width: 100% !important;
    margin: 0px;
  }
`

const OutlinedGrid = styled(Grid)`
  border: 1px solid ${colors.dimGray};
  border-radius: 10px;
  // padding: 20px;
`

const LabelTypography = styled(Typography)`
  padding-right: 10px;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: Jura;
  font-weight: bold !important;
`

const RowLabelTypography = styled(Typography)`
  color: ${colors.dimGray};
  text-transform: uppercase;
  font-size: 14px;
  padding-top: 4px;
`

const ValueTypography = styled(Typography)`
  color: ${colors.white};
  font-family: Jura;
  font-weight: bold !important;
`

const UnderlinedGrid = styled(Grid)`
  border-bottom: 1px solid ${colors.dimGray};
  justify-content: space-between;
  padding: 0px 6px;
  height: 40px;
  align-items: center;
`

const InfoRowGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 26px 0px 26px;
`
const InfoRowGridCell = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 40px;
`

const RowValueTypography = styled(Typography)`
  width: 100%;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
`

type PlanetDetailType = {
  name?: string;
  image?: string;
  description?: string;
  animation_url?: string;
  attributes?: any[];
  category?: string;
  rarity?: string;
  starSector?: string;
  planetaryMass?: string;
  population?: string;
  civilizationLevel?: string;
  gam?: string;
  h2o?: string;
  rareMetal?: string;
  metal?: string;
  radiation?: string;
}


const PlanetDetail = (props) => {
  const { t } = useTranslation("planetDetail");
  const { addError } = useErrorNotification();
  const location = useLocation()
  const history = useHistory()
  const [isRevealingPlanet, setIsReveealingPlanet] = useState(false)
  const [revealAnimationVideo, setRevealAnimationVideo] = useState(null)
  const [planetDtl, setPlanetDtl] = useState<PlanetDetailType>({
    name: "",
    image: "",
    description: "",
    animation_url: "",
    attributes: [],
    category: "",
    rarity: "",
    starSector: "",
    planetaryMass: "",
    population: "",
    civilizationLevel: "",
    gam: "",
    h2o: "",
    rareMetal: "",
    metal: "",
    radiation: "",

  })
  const { signer } = useConnectWallet()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [isOwner, setIsOwner] = useState(false)
  const fromRevealButton = location?.state?.fromRevealButton
  let tokenId = props?.match?.params?.tokenId

  const { planets, isLoading: isLoadingPlanets } = usePlanets();
  const { lootboxes, isLoading: isLoadingLootboxes } = useLootboxes();

  useEffect(() => {
    if (isLoadingPlanets || isLoadingLootboxes) return
    let foundTokenId = false
    for (let i = 0; i < lootboxes.length + planets.length; i++) {
      if (i < lootboxes.length) {
        if (lootboxes[i].tokenId === String(tokenId)) {
          foundTokenId = true
          break
        }
      } else {
        if (planets[i - lootboxes.length].tokenId === String(tokenId)) {
          foundTokenId = true
          break
        }
      }
    }

    setIsOwner(foundTokenId)

  }, [planets, lootboxes, tokenId, signer, isLoadingPlanets, isLoadingLootboxes])

  const onNFTradeBtnClick = useCallback(() => {
    if (tokenId) {
      window.open(`https://nftrade.com/assets/bsc/0x1f099dafba907498e701a72da0007d58f88459de/${tokenId}`);
    }
  }, [tokenId])

  const extractProcessMetadata = (tokenInfoRes) => {
    let attrs = tokenInfoRes.data?.attributes
    let category = attrs.find((e) => e.trait_type === 'Category')?.value
    let rarity = attrs.find((e) => e.trait_type === 'Rarity')?.value
    let starSector = attrs.find((e) => e.trait_type === 'Star Sector')?.value
    let planetaryMass = attrs.find((e) => e.trait_type === 'Planetary Mass')?.value
    let population = attrs.find((e) => e.trait_type === 'Population')?.value
    let civilizationLevel = attrs.find((e) => e.trait_type === 'Civilization Level')?.value
    let gam = attrs.find((e) => e.trait_type === 'GAM Resource')?.value
    let h2o = attrs.find((e) => e.trait_type === 'H2O Resource')?.value
    let rareMetal = attrs.find((e) => e.trait_type === 'Rare Metal Resource')?.value
    let metal = attrs.find((e) => e.trait_type === 'Mineral Resource')?.value
    let radiation = attrs.find((e) => e.trait_type === 'Radiation Resource')?.value

    setPlanetDtl({
      ...tokenInfoRes.data,
      category,
      rarity,
      starSector,
      planetaryMass,
      population,
      civilizationLevel,
      gam,
      h2o,
      rareMetal,
      metal,
      radiation,
    })
  }

  const onRevealBtnClick = useCallback(async () => {
    if ([null, undefined].includes(tokenId)) {
      return
    }

    try {
      setIsReveealingPlanet(true)
      let signedMsg = await signer.signMessage(`PlanetReveal${tokenId}`)
      let tokenInfoRes = await axios.post(`${process.env.REACT_APP_PLANET_REVEAL_API}${tokenId}/reveal`, {
        signature: signedMsg
      })
      if (tokenInfoRes.status === 200) {
        extractProcessMetadata(tokenInfoRes)
        setIsReveealingPlanet(false)
        history.replace({
          ...history.location,
          state: {
            fromRevealButton: false,
            ...history.location.state,
          }
        });
      } else {
        addError({
          label: `${t("reconFailure")}. ${t("notOwnerMsg")}${tokenId}`
        })
      }
    } catch (err) {
      addError(err)
      setIsReveealingPlanet(false)
    }
  }, [signer, history, tokenId])

  useEffect(() => {
    if (fromRevealButton === true) {
      onRevealBtnClick()
    }
  }, [fromRevealButton, onRevealBtnClick])

  useEffect(() => {
    if ([null, undefined].includes(tokenId)) {
      history.push(Path.Dashboard.Root)
    }

    const run = async () => {
      try {
        let tokenInfoRes = await axios.get(`${process.env.REACT_APP_PLANET_INFO}${tokenId}`)
        if (tokenInfoRes.status === 200) {
          extractProcessMetadata(tokenInfoRes)
        }
        setRevealAnimationVideo(tokenInfoRes?.data.animation_url)
      } catch (err) {
        console.log(err)
      }


    }
    run()
  }, [tokenId])

  useEffect(() => {

    return () => {
      history.replace({ ...history.location });
    }
  }, [history])

  const onBackBtnClick = useCallback(() => {
    history.push(Path.Inventory.Root)
  }, [])

  const isRevealed = planetDtl?.attributes?.length > 1

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item xs={12} justifyContent="flex-start">
            <Title name={"Planet Detail"} hasBanner={true} />
          </Grid>

          {
            isRevealingPlanet ? <Grid item container xs={12}>
              <video autoPlay src={`${process.env.PUBLIC_URL}/assets/planet_gacha_loading.mp4`} style={{ width: "100%", height: '100%' }} />
            </Grid> : <Grid item container xs={12} rowGap={"10px"}>

              <OutlinedGrid item container xs={12} lg={6} position={"relative"}>
                <Box
                  onClick={onBackBtnClick}
                  sx={{
                    position: "absolute",
                    top: "20px", left: "20px",
                    cursor: "pointer",
                    borderRadius: "99px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    width: "40px",
                    height: "40px",
                    border: '1px solid #fff'
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#fff" }} />
                </Box>

                <Grid item container xs={12} pt="80px" minHeight="400px" justifyContent={"center"}>
                  {
                    planetDtl?.animation_url ? <video autoPlay src={planetDtl?.animation_url} style={{ width: "70%" }} /> :
                      <Skeleton variant="rectangular" sx={{ width: "70%", height: "400px", borderRadius: "20px" }} />
                  }
                </Grid>
                <Grid item container xs={12} justifyContent={"center"} py="20px" sx={{
                  borderBottom: `1px solid ${colors.dimGray}`
                }}>
                  <Typography variant="h3" textAlign={"center"} sx={{ color: colors.orangeColor }}>{planetDtl?.name}</Typography>
                </Grid>

                {
                  !isLoadingPlanets && !isLoadingLootboxes ? <Grid item container xs={12} columnSpacing={"6px"} rowSpacing={"6px"} p="20px">
                    {
                      !isRevealed && <Grid item container xs={12} lg={12} justifyContent={"center"}>
                        <StyledActionButton sx={{

                        }} onClick={onRevealBtnClick}>
                          {t("reveal")}
                        </StyledActionButton>

                      </Grid>
                    }
                    <Grid item container xs={12} lg justifyContent={"flex-end"}>
                      <StyledActionButton>
                        <Link href={`https://twitter.com/intent/tweet?text=${process.env.REACT_APP_SHOWCASE_API}${tokenId}`} target="_blank"
                          color={colors.white} sx={{ lineHeight: "unset", textDecoration: "none" }}>
                          &nbsp;Share
                        </Link>
                      </StyledActionButton>

                    </Grid>
                    <Grid item container xs={12} lg pl={"12px"} justifyContent={"flex-start"}>

                      <StyledActionButton sx={{
                        background: `linear-gradient(90deg, ${colors.orangeColor} 0%, ${colors.paleRedColor} 100%)`,
                        color: colors.white,
                      }} onClick={onNFTradeBtnClick}>
                        {t("nfTrade")}
                      </StyledActionButton>
                    </Grid>

                  </Grid> : <Grid item container xs={12} height="100px" alignItems={"center"} px="40px">
                    <Skeleton variant={"rectangular"} sx={{ height: "50px", width: "100%" }} />
                  </Grid>
                }

              </OutlinedGrid>

              <Grid item container alignContent="flex-start" xs={12} lg={6} sx={{
                padding: "30px"
              }}>
                <Grid item container xs={12} height="60px">
                  <Typography variant="h2" fontWeight="bold" color={colors.paleRedColor}>{t("attributes")}</Typography>
                </Grid>

                {
                  <Grid item container xs={12} height="200px">
                    {
                      planetDtl?.category && <UnderlinedGrid item container xs={12} justifyContent={"flex-start"}>
                        <LabelTypography variant="h4">{t("category")}</LabelTypography>
                        <ValueTypography variant="h4">{planetDtl?.category}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      planetDtl?.attributes[0]?.value && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("rarity")}</LabelTypography>
                        <ValueTypography variant="h4">{planetDtl?.rarity ?? planetDtl?.attributes[0]?.value}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      planetDtl?.starSector && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("starSector")}</LabelTypography>
                        <ValueTypography variant="h4">{planetDtl?.starSector}</ValueTypography>
                      </UnderlinedGrid>

                    }
                    {
                      planetDtl?.planetaryMass && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("planetaryMass")}</LabelTypography>
                        <ValueTypography variant="h4">{planetDtl?.planetaryMass}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      planetDtl?.population && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("population")}</LabelTypography>
                        <ValueTypography variant="h4">{planetDtl?.population}</ValueTypography>
                      </UnderlinedGrid>
                    }
                  </Grid>
                }

                {
                  planetDtl?.civilizationLevel && <Grid container item xs={12} height={"calc(100% - 260px)"} alignContent={"stretch"}>
                    <InfoRowGrid container item xs={12} sx={{
                      borderBottom: !isMd ? `1px solid ${colors.dimGray}` : "unset",
                      paddingBottom: !isMd ? '10px' : "0px"
                    }}>
                      <InfoRowGridCell item container xs={12} lg>
                        <RowValueTypography variant="h4">{planetDtl?.civilizationLevel}</RowValueTypography>
                        <RowLabelTypography variant="h3">{t("civilizationLevel")}</RowLabelTypography>
                      </InfoRowGridCell>
                      <InfoRowGridCell item container xs={12} lg sx={{
                        borderLeft: !isMd ? `1px solid ${colors.dimGray}` : "unset",
                        borderRight: !isMd ? `1px solid ${colors.dimGray}` : "unset",
                      }}>
                        <RowValueTypography variant="h4">{planetDtl?.gam}</RowValueTypography>
                        <RowLabelTypography variant="h3">{t("GAMResource")}</RowLabelTypography>
                      </InfoRowGridCell>
                      <InfoRowGridCell item container xs={12} lg>
                        <RowValueTypography variant="h4">{planetDtl?.h2o}</RowValueTypography>
                        <RowLabelTypography variant="h3">{t("H2OResource")}</RowLabelTypography>
                      </InfoRowGridCell>
                    </InfoRowGrid>

                    <InfoRowGrid container item xs={12}>
                      <InfoRowGridCell item container xs={12} lg>
                        <RowValueTypography variant="h4">{planetDtl?.rareMetal}</RowValueTypography>
                        <RowLabelTypography variant="h4">{t("rareMetal")}</RowLabelTypography>
                      </InfoRowGridCell>
                      <InfoRowGridCell item container xs={12} lg sx={{
                        borderLeft: !isMd ? `1px solid ${colors.dimGray}` : "unset",
                        borderRight: !isMd ? `1px solid ${colors.dimGray}` : "unset",
                      }}>
                        <RowValueTypography variant="h4">{planetDtl?.metal}</RowValueTypography>
                        <RowLabelTypography variant="h4">{t("metal")}</RowLabelTypography>
                      </InfoRowGridCell>
                      <InfoRowGridCell item container xs={12} lg>
                        <RowValueTypography variant="h4">{planetDtl?.radiation}</RowValueTypography>
                        <RowLabelTypography variant="h4">{t("radiation")}</RowLabelTypography>
                      </InfoRowGridCell>
                    </InfoRowGrid>
                  </Grid>
                }

              </Grid>
            </Grid>
          }

        </Grid>
      </Box>
    </Container>
  );
};
export default PlanetDetail;
