import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { colors } from 'utils/Constants'
import {
  useLocation
} from 'react-router-dom'
import Path from "@routes/path";

const Title = (props) => {
  const location = useLocation()
  const {
    name, hasBanner = false, titleRightComponent = undefined
  } = props

  const onDappBannerClick = useCallback(() => {
    window.open('https://bit.ly/MWARSPlanetFiAirdrop', '_target')
  }, [])

  return (
    <>
      {
        hasBanner && <img onClick={onDappBannerClick} src={`${process.env.PUBLIC_URL}/images/dappBanner.gif`} style={{width: "100%", cursor: 'pointer'}}/>
      }
      <Grid container xs={12} justifyContent="space-between" sx={{}}>
        <Typography variant="h4" 
          sx={{color: colors.orangeColor, fontSize: '36px'}} 
        >{name}</Typography>
        {
          titleRightComponent
        }
      </Grid>
    </>
  )
};

export default Title;
