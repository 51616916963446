import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline, Box } from "@mui/material";
import "./index.css";
import DesktopFrame from "@components/templates/DesktopFrame";
import MobileFrame from "@components/templates/MobileFrame";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ConnectWalletProvider } from "@contexts/ConnectWalletContext";
import { ContractsProvider } from "@contexts/ContractsContext";
import { ErrorNotificationProvider } from "@contexts/ErrorNotificationContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import background from './images/bg.jpg'

const Frame: React.FC = ({ children }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const FrameComponent = React.useMemo(
    () => (isMobile ? MobileFrame : DesktopFrame),
    [isMobile]
  );
  return <FrameComponent>{children}</FrameComponent>;
};

function App() {
  return (
    <Box sx={{
      backgroundImage: `url(${background})`
    }}> 
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorNotificationProvider>
          <ConnectWalletProvider>
            <ContractsProvider>
              <Router>
                <Frame>
                  <Routes />
                </Frame>
              </Router>
            </ContractsProvider>
          </ConnectWalletProvider>
        </ErrorNotificationProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;
