import React, { useState } from "react";
import Greeting from "../containers/wallet/Greeting";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import RewardsOverview from "@containers/staking/RewardsOverview";
import PoolCard from "@containers/staking/PoolCard";
import { StakingPool } from "../types/staking";
import { useTranslation } from "react-i18next";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import RemarksBanner from "@components/shared/RemarksBanner";
import Title from "@components/shared/Title";
import StakingSwitch from "@containers/staking/StakingSwitch";
import { NavHashLink } from 'react-router-hash-link';

const Staking = () => {
  const { isChainIdCorrect } = useConnectWallet();
  const { t } = useTranslation("staking");

  const [stakingStateIdx, setStakingStateIdx] = useState(0)
  const [currStakingPools, setCurrStakingPools] = useState([])
  const [currStakingPoolLinks, setCurrStakingPoolLinks] = useState([])

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, minWidth: ["100%", "800px"], height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>

          <Grid item xs={12} justifyContent="flex-start">
            <Title name={t('title')} />
            <Typography variant="h5" pt={3} maxWidth="md">{t("description")}</Typography>
          </Grid>

          {isChainIdCorrect ? (
            <Grid item xs={12} pb={4} maxWidth="md">
              <RewardsOverview />
            </Grid>
          ) : null}




            <Grid item xs={12} spacing={4} container>
              {isChainIdCorrect ? (
                <>
                  <Grid item container xs={12} justifyContent="center">
                    <StakingSwitch
                      stakingStateIdx={stakingStateIdx}
                      setStakingStateIdx={setStakingStateIdx}
                    />
                  </Grid>
                  
                    <PoolCard pool={StakingPool.WxMStakingW} showFinished={stakingStateIdx === 1} id={'wxmStaking'} />
            
                    <PoolCard pool={StakingPool.WarsBusdLp1} showFinished={stakingStateIdx === 1} id={'warslp1'} />
          
                    <PoolCard pool={StakingPool.WarsBusdLp2} showFinished={stakingStateIdx === 1} id={'warslp2'} />
           
                    <PoolCard pool={StakingPool.WarsBusdLpGAM} showFinished={stakingStateIdx === 1} id={'warslpGAM'} />
          
                    <PoolCard pool={StakingPool.Wars} showFinished={stakingStateIdx === 1} id={'wars'} />
        

                </>
              ) : (
                <Grid item xs={12} mt="10px" pl="42px !important">
                  <RemarksBanner height={200}>
                    <Typography variant="h2">{t("chain.switch")}</Typography>
                  </RemarksBanner>
                </Grid>
              )}
            </Grid>

        </Grid>
      </Box>
    </Container>
  );
};
export default Staking;
