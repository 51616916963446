import React, { createContext, useContext } from "react";
import { ethers, Contract } from "ethers";
import deploymentsArtifacts from "../hardhat/artifacts.json";
import { InventoryProvider } from "./InventoryContext";
import { StakingProvider } from "./StakingContext";
import { VestingProvider } from "./VestingContext";
import { useConnectWallet } from "./ConnectWalletContext";
import Debug from "debug";

const debug = Debug("web:contracts");

export enum ContractName {
  Vesting = "TokenVesting",
  NFT = "RobotNFT",
  WARSToken = "WARSToken",
  WarsBusdLpToken = "StakeToken",
  GAMToken = "GAMToken",
  WARSStaking = "WARSStaking",
  WarsBusdLpStaking = "WARSBUSDLPStaking",
  WarsBusdLpStaking1 = "WARSBUSDLPStaking1",
  WarsBusdLpStaking2 = "WARSBUSDLPStaking2",
  PlanetLottery = "PlanetLottery",
  Planet = "Planet",
  PlanetFusion = "PlanetFusion",
  NFTRegistry = "NFTRegistry",
  WxMStakingW = "WxMStakingW",
  RaceGameTokenVendor = "RaceGameTokenVendor",
}

export type ContractsProps = { [key in ContractName]?: Contract };

export const ContractsContext = createContext<ContractsProps>({});

export const useContracts = () => {
  const context = useContext(ContractsContext);
  return context;
};

export const ContractsProvider: React.FC = ({ children }) => {
  const { signer, chainId } = useConnectWallet();

  const getContract = React.useCallback(
    (name: ContractName) => {
      if (signer) {
        let defaultArtifact, deploymentArtifact, contractArtifact;

        //default chain id
        defaultArtifact = deploymentsArtifacts[chainId];
        if (defaultArtifact) {
          defaultArtifact = Object.values(defaultArtifact)[0];
        }

        //deployment chain id
        deploymentArtifact =
          deploymentsArtifacts[process.env["REACT_APP_DEPLOYMENT_CHAIN_ID"]];
        if (deploymentArtifact) {
          deploymentArtifact = Object.values(deploymentArtifact)[0];
        }

        if (defaultArtifact && defaultArtifact.contracts[name]) {
          contractArtifact = defaultArtifact.contracts[name];
        } else if (deploymentArtifact && deploymentArtifact.contracts[name]) {
          contractArtifact = deploymentArtifact.contracts[name];
        } else {
          return null;
        }

        let address = contractArtifact.address;
        if (
          process.env[`REACT_APP_CONTRACT_${name.toString().toUpperCase()}`]
        ) {
          address =
            process.env[`REACT_APP_CONTRACT_${name.toString().toUpperCase()}`];
        }

        debug(name, address);

        return new ethers.Contract(address, contractArtifact.abi, signer);
      }
      return null;
    },
    [signer, chainId]
  );

  const value = React.useMemo(() => {
    const vesting = getContract(ContractName.Vesting);
    const nft = getContract(ContractName.NFT);
    const WARSToken = getContract(ContractName.WARSToken);
    const warsBusdLpToken = getContract(ContractName.WarsBusdLpToken);
    const GAMToken = getContract(ContractName.GAMToken);

    const WARSStaking = getContract(ContractName.WARSStaking);
    const warsBusdLpStaking = getContract(ContractName.WarsBusdLpStaking);
    const warsBusdLpStaking1 = getContract(ContractName.WarsBusdLpStaking1);
    const warsBusdLpStaking2 = getContract(ContractName.WarsBusdLpStaking2);

    const planetLottery = getContract(ContractName.PlanetLottery);
    const planet = getContract(ContractName.Planet);
    const planetFusion = getContract(ContractName.PlanetFusion);
    const nftRegistry = getContract(ContractName.NFTRegistry);
    const wxMStakingW = getContract(ContractName.WxMStakingW);

    const raceGameTokenVendor = getContract(ContractName.RaceGameTokenVendor);

    return {
      [ContractName.Vesting]: vesting,
      [ContractName.NFT]: nft,
      [ContractName.WARSToken]: WARSToken,
      [ContractName.WarsBusdLpToken]: warsBusdLpToken,
      [ContractName.GAMToken]: GAMToken,
      [ContractName.WARSStaking]: WARSStaking,
      [ContractName.WarsBusdLpStaking]: warsBusdLpStaking,
      [ContractName.WarsBusdLpStaking1]: warsBusdLpStaking1,
      [ContractName.WarsBusdLpStaking2]: warsBusdLpStaking2,
      [ContractName.Planet]: planet,
      [ContractName.PlanetLottery]: planetLottery,
      [ContractName.PlanetFusion]: planetFusion,
      [ContractName.NFTRegistry]: nftRegistry,
      [ContractName.WxMStakingW]: wxMStakingW,
      [ContractName.RaceGameTokenVendor]: raceGameTokenVendor,
    };
  }, [getContract]);

  return (
    <ContractsContext.Provider value={value}>
      <InventoryProvider>
        <VestingProvider>
          <StakingProvider>{children}</StakingProvider>
        </VestingProvider>
      </InventoryProvider>
    </ContractsContext.Provider>
  );
};
