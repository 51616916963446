const staking = {
  title: "Liquidity Staking",
  description:
    "Are you ready to build your army and be well prepared for the war? Here comes the chance, by staking $WARS and LP token, you would be rewarded. $WARS and $GAM is the key for survival in the MetaWars’ gaming ecosystem. Hence, stake your $WARS and LP token to pile up your tokens.",
  "field.totalStakingLiquidity.label": "Total Staked",
  "field.yourReward.label": "Your Reward",
  "field.warsBalance.label": "$WARS Balance",
  "field.stkBalance.label": "$STK Balance",
  "field.gamEarned.label": "GAM Earned",
  "field.apr.label": "APR",
  "field.tvl.label": "Total Value Locked",
  "field.claimable.label": "Reward Claimable",
  "field.available_to_claim.label": "Available to Claim",
  "field.staked.label": "Staked",
  "field.stakedWARSLP.label": "Staked WARS LP",
  "field.stakedWARS.label": "Staked WARS",
  "action.stake.default": "Stake",
  "action.stake.approve": "Approving",
  "action.stake.deposit": "Depositing",
  "action.stake.update": "Please wait",
  "action.unstake.default": "Unstake",
  "action.unstake.withdraw": "Unstaking",
  "action.unstake.update": "Please wait",
  "action.harvest.default": "Harvest",
  "action.harvest.withdraw": "Harvesting",
  "action.harvest.update": "Please wait",
  "form.stake.amount": "Enter Amount",
  "form.stake.confirm": "Confirm Staking",
  "stake.completed": "Successfully staked {{amount}} {{unit}}. Please wait.",
  "unstake.completed": "Successfully unstaked!",
  "harvest.completed": "Successfully harvested!",
  "chain.switch": "Connect to start staking",

  "wars.feature.1": "Stake your WARS to mine GAM",
  "wars.feature.2": "Accumulate your GAM to prepare for the wars",

  "warsbusdlp.feature.1":
    "Provide liquidity to the PancakeSwap Pool to mine GAM",
  "warsbusdlp.feature.2": "Accumulate your GAM to prepare for the wars",

  "WxMStakingW.feature.1": "Top 10 stakers will be guaranteed to receive a cross-over NFT",
  "WxMStakingW.feature.2": "All stakers who staked over 2000 $WARS will be eligible to join the lucky draw on 20 Jan 2022 (2000 $WARS per entry, the more you stake the higher chance to win the NFT)",
  "WxMStakingW.feature.3": "Lock-up period: 45 days (unstake will be available on 28 Feb 2022)",
  "WxMStakingW.feature.4": "",

  "warsbusdlp1.feature.1":
    "Provide liquidity to the PancakeSwap Pool to earn WARS",
  "warsbusdlp1.feature.2": "Limited Time Staking Pool with 30 days time lock (Expiry date 26 April 2022) ",

  "warsbusdlp2.feature.1":
    "Provide liquidity to the PancakeSwap Pool to earn WARS",
  "warsbusdlp2.feature.2": "Limited Time Staking Pool with 60 days time lock (Expiry date 26 May 2022) ",

  "warsbusdlpGAM.feature.1":
    "Provide liquidity to the PancakeSwap Pool to mine GAM",
  "warsbusdlpGAM.feature.2": "Accumulate your GAM to prepare for the wars",



};
export default staking;
