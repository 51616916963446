import React, { createContext, useContext, useReducer } from "react";
import { ethers, Contract } from "ethers";
import { useConnectWallet } from "./ConnectWalletContext";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import { useContracts, ContractName } from "./ContractsContext";
import { StakingPool } from "../types/staking";
import Debug from "debug";
import { Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const debug = Debug("web:staking");

export enum StakingActionType {
  Stake = "stake",
  Unstake = "unstake",
  Harvest = "harvest",
}

export type StakingPoolAction = {
  [StakingActionType.Stake]: () => void;
  [StakingActionType.Unstake]: () => void;
  [StakingActionType.Harvest]: () => void;
};

type StakingPoolValues = {
  amountStaked?: number;
  pendingReward?: number;
  tokenBalance?: number;
  totalValueLocked?: number;
  stakedTokenName: string;
  contractName: string;
  stakingPoolTitle: string;
  stakedTokenLabel: string;
  tokenLabel: string;
  rewardTokenLabel: string;
  poolRewardAmount: number;
  featuresLabel?: string[];
  tradeLink?: string;
  totalDeposited?: number;
  isFinished?: boolean;
  hasApr?: boolean;
  isPoolFinished?: boolean;
  poolDurationDays?: number;
};

const getStakingPoolProps = (pool: StakingPool) =>
({
  [StakingPool.Wars]: {
    stakedTokenName: ContractName.WARSToken,
    contractName: ContractName.WARSStaking,
    hasApr: false,
    stakingPoolTitle: "WARS Staking",
    stakedTokenLabel: "WARS",
    isPoolFinished: false,
    rewardTokenLabel: "$GAM",
    // tradeLink: "",
    featuresLabel: ["wars.feature.1", "wars.feature.2"],
    poolDurationDays: -1
  },
  [StakingPool.WarsBusdLp]: {
    stakedTokenName: ContractName.WarsBusdLpToken,
    contractName: ContractName.WarsBusdLpStaking,
    stakingPoolTitle: "WARS-BUSD LP",
    stakedTokenLabel: "WARS-BUSD LP",
    rewardTokenLabel: "$GAM",
    featuresLabel: ["warsbusdlp.feature.1", "warsbusdlp.feature.2"],
    tradeLink: "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
    poolDurationDays: -1
  },
  [StakingPool.WarsBusdLp1]: {
    stakedTokenName: ContractName.WarsBusdLpToken,
    contractName: ContractName.WarsBusdLpStaking1,
    hasApr: true,
    isPoolFinished: false,
    stakingPoolTitle: "WARS-BUSD LP 2.0",
    stakedTokenLabel: "WARS-BUSD LP",
    rewardTokenLabel: "$WARS",
    poolRewardAmount: 500000,
    featuresLabel: ["warsbusdlp1.feature.1", "warsbusdlp1.feature.2"],
    tradeLink: "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
    poolDurationDays: 30
  },
  [StakingPool.WarsBusdLp2]: {
    stakedTokenName: ContractName.WarsBusdLpToken,
    contractName: ContractName.WarsBusdLpStaking2,
    hasApr: true,
    isPoolFinished: false,
    stakingPoolTitle: "WARS-BUSD LP 2.0",
    stakedTokenLabel: "WARS-BUSD LP",
    rewardTokenLabel: "$WARS",
    poolRewardAmount: 500000,
    featuresLabel: ["warsbusdlp2.feature.1", "warsbusdlp2.feature.2"],
    tradeLink: "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
    poolDurationDays: 60
  },
  [StakingPool.WarsBusdLpGAM]: {
    stakedTokenName: ContractName.WarsBusdLpToken,
    contractName: ContractName.WarsBusdLpStaking,
    hasApr: false,
    isPoolFinished: false,
    stakingPoolTitle: "WARS-BUSD LP (GAM) ",
    stakedTokenLabel: "WARS-BUSD LP",
    rewardTokenLabel: "$GAM",
    featuresLabel: ["warsbusdlpGAM.feature.1", "warsbusdlpGAM.feature.2"],
    tradeLink: "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
    poolDurationDays: -1
  },
  [StakingPool.WxMStakingW]: {
    stakedTokenName: ContractName.WARSToken,
    contractName: ContractName.WxMStakingW,
    stakingPoolTitle: "MetaGods Crossover",
    stakedTokenLabel: "MetaGods Crossover",
    rewardTokenLabel: "$WARS",
    isPoolFinished: true,
    featuresLabel: ["WxMStakingW.feature.1", "WxMStakingW.feature.2", "WxMStakingW.feature.3"],
    // tradeLink: "",
    poolDurationDays: -1
  },
}[pool]);

const initialValues = {
  actions: {
    [StakingActionType.Stake]: undefined,
    [StakingActionType.Unstake]: undefined,
    [StakingActionType.Harvest]: undefined,
  },
};

type StakingProps = {
  actions: StakingPoolAction;
  values?: StakingPoolValues;
};
export type StakingActiveEvents = {
  events: { type: string; active: boolean }[];
  pool: StakingPool;
  actionType: StakingActionType;
};

export type StakingContextProps = {
  [StakingPool.Wars]: StakingProps;
  [StakingPool.WarsBusdLp]: StakingProps;
  [StakingPool.WxMStakingW]: StakingProps;
  [StakingPool.WarsBusdLp1]: StakingProps;
  [StakingPool.WarsBusdLp2]: StakingProps;
  [StakingPool.WarsBusdLpGAM]: StakingProps;
  activeEvents?: StakingActiveEvents;
};

export const StakingContext = createContext<StakingContextProps>({
  [StakingPool.Wars]: initialValues,
  [StakingPool.WarsBusdLp]: initialValues,
  [StakingPool.WarsBusdLp1]: initialValues,
  [StakingPool.WarsBusdLp2]: initialValues,
  [StakingPool.WarsBusdLpGAM]: initialValues,
  [StakingPool.WxMStakingW]: initialValues,
});

export const useStaking = () => {
  const context = useContext(StakingContext);

  return context;
};
enum ReducerActionType {
  UpdatePool = "update-pool",
  AddActiveEvents = "add-active-event",
  UpdateActiveEvent = "update-active-event",
  ResetActiveEvents = "reset-active-events",
  ResetPool = "reset-pool",
}
const reducer = (state, { type, ...actionProps }) => {
  switch (type) {
    case ReducerActionType.UpdatePool: {
      return {
        ...state,
        [actionProps.pool]: {
          actions: actionProps.actions,
          values: actionProps.values,
        },
      };
    }
    case ReducerActionType.ResetPool: {
      return {
        ...state,
        [actionProps.pool]: undefined,
      };
    }
    case ReducerActionType.AddActiveEvents: {
      return {
        ...state,
        activeEvents: {
          pool: actionProps.pool,
          actionType: actionProps.actionType,
          events: actionProps.events,
        },
      };
    }
    case ReducerActionType.UpdateActiveEvent: {
      if (
        actionProps.pool !== state.activeEvents?.pool ||
        actionProps.actionType !== state.activeEvents?.actionType
      ) {
        return state;
      }
      const updatedEvents = state.activeEvents?.events?.map((event) => {
        if (event.type === actionProps.eventType) {
          return { ...event, active: true };
        }
        return { ...event, active: false };
      });
      return {
        ...state,
        activeEvents: {
          pool: actionProps.pool,
          actionType: actionProps.actionType,
          events: updatedEvents,
        },
      };
    }
    case ReducerActionType.ResetActiveEvents: {
      return {
        ...state,
        activeEvents: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export const StakingProvider: React.FC = ({ children }) => {
  const { t } = useTranslation("staking");
  const { address, provider } = useConnectWallet();
  const { addError } = useErrorNotification();
  const [state, dispatch] = useReducer(reducer, {});
  const [successMsg, setSuccessMsg] = React.useState("");

  const contracts = useContracts();

  const getContractValues = React.useCallback(
    async (contractInstance: Contract, pool: StakingPool) => {
      const userInfo = await contractInstance.userInfo(address);
      const amountStaked =
        userInfo?.amount && ethers.utils.formatEther(userInfo.amount);
      const hasStaked = parseFloat(amountStaked) > 0;
      const pendingReward = hasStaked
        ? await contractInstance.pendingReward(address)
        : undefined;
      // const totalDeposited = pool === StakingPool.MGODStaking ? await contractInstance.totalDeposited() : 0;
      const bonusEndBlock = await contractInstance.bonusEndBlock();
      const currentBlock = provider ? ethers.BigNumber.from(await provider.getBlockNumber()) : ethers.BigNumber.from(0);
      let canStake = true;
      if (contractInstance.stakeEndBlock) {
        const stakeEndBlock = await contractInstance.stakeEndBlock();
        canStake = stakeEndBlock.gt(currentBlock);
      }
      //const canUnstaked = hasStaked ;
      const canUnstakeNormal = hasStaked && pool != StakingPool.WxMStakingW;
      const canUnstakeCrossoverPool = hasStaked && pool === StakingPool.WxMStakingW && provider
        && (!contractInstance.bonusEndBlock || bonusEndBlock.lt(currentBlock));
      const canUnstaked = canUnstakeNormal || canUnstakeCrossoverPool;
      const isFinished = provider && bonusEndBlock?.lt(currentBlock);

      const stakingPoolProps = getStakingPoolProps(pool);

      const totalValueLocked = await contracts[
        stakingPoolProps.stakedTokenName
      ]?.balanceOf(contractInstance.address);
      const tokenBalance = await contracts[
        stakingPoolProps.stakedTokenName
      ]?.balanceOf(address);

      const values = {
        totalValueLocked: ethers.utils.formatEther(totalValueLocked || "0"),
        amountStaked: amountStaked || 0,
        pendingReward:
          (pendingReward && ethers.utils.formatEther(pendingReward)) || 0,
        //totalDeposited:
        //  (totalDeposited && ethers.utils.formatEther(totalDeposited)) || 0,
        tokenBalance:
          (tokenBalance && ethers.utils.formatEther(tokenBalance)) || 0,
        isFinished: isFinished,
        ...stakingPoolProps,
      };
      const hasPendingReward = values.pendingReward > 0;

      /* actions */
      const stake = async (stakeAmount: number) => {
        try {
          const amount = ethers.utils.parseEther(`${stakeAmount}`);
          debug(`[${pool}] staking ${amount}`);
          dispatch({
            type: ReducerActionType.AddActiveEvents,
            pool,
            actionType: StakingActionType.Stake,
            events: [
              {
                type: "approve",
                active: true,
              },
              {
                type: "deposit",
                active: false,
              },
              {
                type: "update",
                active: false,
              },
            ],
          });
          // approve
          const stakedTokenContract =
            contracts[stakingPoolProps.stakedTokenName];
          debug(`[${pool}] approval starts`);
          const approve = await stakedTokenContract?.approve(
            contractInstance.address,
            amount
          );
          await approve.wait();
          debug(`[${pool}] approval completed`);
          dispatch({
            type: ReducerActionType.UpdateActiveEvent,
            pool,
            actionType: StakingActionType.Stake,
            eventType: "deposit",
          });
          debug(`[${pool}] deposit starts`);
          const tx2 = await contractInstance.deposit(amount);
          await tx2.wait();
          debug(`[${pool}] deposit completed`);
          setSuccessMsg(
            t("stake.completed", {
              amount: stakeAmount,
              unit: stakingPoolProps.stakedTokenLabel,
              br: () => <br />,
            })
          );
          dispatch({
            type: ReducerActionType.UpdateActiveEvent,
            pool,
            actionType: StakingActionType.Stake,
            eventType: "update",
          });
        } catch (e) {
          debug(e.message);
          addError({
            label: e.message,
          });
          dispatch({ type: ReducerActionType.ResetActiveEvents });
        }
      };
      const withdraw = (type: StakingActionType) => async () => {
        const amount = type === StakingActionType.Unstake ? userInfo.amount : 0;
        try {
          dispatch({
            type: ReducerActionType.AddActiveEvents,
            pool,
            actionType: type,
            events: [
              {
                type: "withdraw",
                active: true,
              },
              {
                type: "update",
                active: false,
              },
            ],
          });
          const tx = await contractInstance.withdraw(amount);
          await tx.wait();
          setSuccessMsg(t(`${type}.completed`));

          dispatch({
            type: ReducerActionType.UpdateActiveEvent,
            pool,
            actionType: StakingActionType.Unstake,
            eventType: "update",
          });
        } catch (e) {
          dispatch({ type: ReducerActionType.ResetActiveEvents });
          debug("withdraw", e.message);
          addError({
            label: e.message,
          });
        }
      };

      return {
        values,
        actions: {
          [StakingActionType.Stake]: canStake
            ? stake
            : undefined,
          [StakingActionType.Unstake]: canUnstaked
            ? withdraw(StakingActionType.Unstake)
            : undefined,
          [StakingActionType.Harvest]: hasPendingReward
            ? withdraw(StakingActionType.Harvest)
            : undefined,
        },
      };
    },
    [addError, address, provider, contracts, t]
  );

  const registerContract = React.useCallback(
    async (contractInstance: Contract, pool: StakingPool) => {
      const update = async () => {
        debug(`[${pool}] Updating pool values`);
        const updatedPoolValues = await getContractValues(
          contractInstance,
          pool
        );
        dispatch({
          type: ReducerActionType.UpdatePool,
          pool,
          ...updatedPoolValues,
        });
        dispatch({
          type: ReducerActionType.ResetActiveEvents,
        });
      };

      contractInstance.on("Withdraw", (sender, amount) => {
        if (sender === address) {
          debug(`[${pool}] on withdraw`);
          update();
        }
      });
      contractInstance.on("Deposit", (sender, amount) => {
        if (sender === address) {
          debug(`[${pool}] on deposit`);
          update();
        }
      });
      return () => {
        if (contractInstance) {
          contractInstance.removeAllListeners();
        }
      };
    },
    [address, getContractValues]
  );

  const init = React.useCallback(
    async (contractInstance: Contract, pool: StakingPool) => {
      if (address) {
        try {
          const poolValues = await getContractValues(contractInstance, pool);
          dispatch({
            type: ReducerActionType.UpdatePool,
            pool,
            ...poolValues,
          });
          registerContract(contractInstance, pool);
        } catch (e) {
          debug("init", e.message);
          console.log(e.data?.message || e.message)
          addError({
            label: "Something went wrong.",
          });
        }
      }
    },
    [address, getContractValues, addError, registerContract]
  );

  const initialisePool = React.useCallback(
    (pool: StakingPool) => {
      const stakingContract = getStakingPoolProps(pool);
      if (contracts[stakingContract.contractName]) {
        init(contracts[stakingContract.contractName], pool);
      } else {
        dispatch({
          type: ReducerActionType.ResetPool,
          pool,
        });
      }
    },
    [contracts, init]
  );

  React.useEffect(() => {
    initialisePool(StakingPool.Wars);
    initialisePool(StakingPool.WarsBusdLp);
    initialisePool(StakingPool.WarsBusdLp1);
    initialisePool(StakingPool.WarsBusdLp2);
    initialisePool(StakingPool.WarsBusdLpGAM);
    initialisePool(StakingPool.WxMStakingW);
  }, [initialisePool]);

  const value = React.useMemo(() => {
    return state;
  }, [state]);
  return (
    <StakingContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!successMsg}
        onClose={() => setSuccessMsg("")}
        autoHideDuration={3000}
        message={successMsg}
        ContentProps={{
          sx: {
            background: (theme) => theme.palette.background.default,
            border: (theme) => `2px solid ${theme.palette.primary.main}`,
            color: "#fff",
            fontSize: "1.25rem",
            textAlign: "center",
          },
        }}
      />
    </StakingContext.Provider>
  );
};
