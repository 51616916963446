import React from "react";
import Link from "@mui/material/Link";

const LogoLink = () => {
  return (
    <Link href="https://metawars.gg/" target="_blank" sx={{ px: 2 }}>
      <img
        src={`${process.env.PUBLIC_URL}/images/Logo.png`}
        alt="logo"
        width="80%"
        style={{
        }}
      />
    </Link>
  );
};
export default LogoLink;
