import React, { createContext, useContext, useReducer } from "react";
import { ethers } from "ethers";
import { useConnectWallet } from "./ConnectWalletContext";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import { useContracts, ContractName } from "./ContractsContext";
import Debug from "debug";

const debug = Debug("web:vesting");

export interface VestingProps {
  totalAmount?: number;
  unclaimedAmount?: number;
  amountClaimed?: number;
  dayVested?: number;
  claimVestedTokens?: () => void;
  hasVesting?: boolean;
  progress?: number;
  disableClaim?: boolean;
  connected?: boolean;
}

export const VestingContext = createContext<VestingProps>({});

export const useVesting = () => {
  const context = useContext(VestingContext);

  return context;
};

const initialState = {
  totalAmount: 0,
  unclaimedAmount: 0,
  amountClaimed: 0,
  dayVested: 0,
  progress: 0,
};

const reducer = (state, { type, ...actionProps }) => {
  switch (type) {
    case "init":
      return {
        connected: true,
        ...initialState,
        ...actionProps,
      };
    case "update": {
      return {
        ...state,
        ...actionProps,
      };
    }
    case "reset": {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export const VestingProvider: React.FC = ({ children }) => {
  const { address } = useConnectWallet();
  const { addError } = useErrorNotification();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { [ContractName.Vesting]: vestingContract } = useContracts();

  const retrieveContractValues = React.useCallback(async () => {
    const [dayVested, unclaimedAmount] =
      await vestingContract.calculateUnclaimedAmount(address);
    const vesting = await vestingContract.vestings(address);
    const amountClaimed = vesting.amountClaimed;
    const totalAmount = vesting.totalAmount;

    const vested = amountClaimed.add(unclaimedAmount);
    const progress = (vested / totalAmount) * 100;
    return {
      totalAmount: totalAmount && ethers.utils.formatEther(totalAmount),
      unclaimedAmount:
        unclaimedAmount && ethers.utils.formatEther(unclaimedAmount),
      amountClaimed: amountClaimed && ethers.utils.formatEther(amountClaimed),
      dayVested,
      progress,
    };
  }, [vestingContract, address]);

  const init = React.useCallback(async () => {
    if (vestingContract && address) {
      try {
        const hasVesting = await vestingContract.hasVesting(address);

        let contractValues;

        if (hasVesting) {
          contractValues = await retrieveContractValues();
        }

        dispatch({
          type: "init",
          hasVesting,
          ...contractValues,
        });
      } catch (e) {
        debug(e.message);
        addError({
          label: e.message,
        });
      }
    } else {
      dispatch({
        type: "reset",
      });
    }
  }, [vestingContract, address, retrieveContractValues, addError]);

  React.useEffect(() => {
    init();
  }, [init]);

  const claimVestedTokens = React.useCallback(async () => {
    if (vestingContract && address) {
      try {
        const tx = await vestingContract.claimVestedTokens(address);
        await tx.wait();
        const updatedContractValues = await retrieveContractValues();

        dispatch({
          type: "update",
          ...updatedContractValues,
        });
      } catch (e) {
        debug(e.message);
        addError({
          label: e.message,
        });
      }
    }
  }, [vestingContract, address, addError, retrieveContractValues]);

  const disableClaim = React.useMemo(() => {
    return !state.unclaimedAmount || !parseFloat(state.unclaimedAmount);
  }, [state]);

  const value = React.useMemo(() => {
    return { ...state, claimVestedTokens, disableClaim };
  }, [state, claimVestedTokens, disableClaim]);
  return (
    <VestingContext.Provider value={value}>{children}</VestingContext.Provider>
  );
};
