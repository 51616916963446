const planetDetail = {
  title: "Planet Detail",
  attributes: "ATTRIBUTES",
  reconFailure: 'Recon Failure',
  notOwnerMsg: "You are not the owner of Planet #",
  share: "SHARE",
  nfTrade: "NFTRADE",
  reveal: "REVEAL",
  back: "Back",
  category: "Category",
  rarity: "Rarity",
  starSector: "Star Sector",
  planetaryMass: "Planetary Mass",
  population: "Population",
  civilizationLevel: "Civilization Level",
  GAMResource: "GAM Resource",
  H2OResource: "H2O Resource",
  rareMetal: "Rare Metal",
  metal: "Metal",
  radiation: "Radiation",
};
export default planetDetail;
