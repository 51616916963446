import WidgetBot from '@widgetbot/react-embed'
import { IconButton, Typography, Link, Stack, Box } from '@mui/material'
import { FaDiscord } from 'react-icons/fa';
import { colors } from '@utils/Constants'

const DiscordWidget = (props) => {
  const { width, height, onAPI, focusable } = props

  return (
    <Box sx={{width: '100%'}}>
      
      <Box sx={{display: "inline-flex", paddingBottom: '10px'}}>
        <Link href={'https://discord.com/channels/889399269059096606'} target='_blank'>
            <IconButton sx={{color: colors.discordColor }}>
              <FaDiscord />
            </IconButton>
          </Link>
        <Typography sx={{
          fontSize: "18px",
          margin: 'auto auto auto 10px', color: colors.discordColor, 
          fontWeight: 'bold'}}>
          Discord
        </Typography>
      </Box>

      
      <Box>
        <WidgetBot
          server={"889399269059096606"}
          channel={"889409735818903552"}
          onAPI={onAPI}
          focusable={true}
          width={width ?? '100%'}
          height={height ?? 700}
        />
      </Box>

    </Box>

      
  )
}

export default DiscordWidget