import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { toTitleCase } from "@utils/valueDisplayHelper";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";

const ItemDetails = (props) => {
  const { contractAddress, tokenId } = props;
  const { addError } = useErrorNotification();

  const [asset, setAsset] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const properties = ["rarity", "slot", "broken"];
  const stats = ["item_level", "hp", "armor", "energy", "brainpower", "damage", "physique", "damage_elemental", "crit_chance", "bleed", "poison", "threat", "reflex", "stun"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}/`)
        const result = await response.json();
        if (result.success === false) {
          addError({ label: "Unable to find this NFT" });
        } else {
          setAsset(result)
        }
      } catch (err) {
        addError ({ label: err.message });
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const traits = useMemo(() => {
    if (!asset["traits"]) return {};

    const attributes = {};

    for (const trait of asset["traits"]) {
      attributes[trait["trait_type"]] = trait;
    }
    return attributes;
  }, [asset]);

  const imageSource = useMemo(() => {
    if (!asset["image_url"]) return "";

    return ["https://storage.opensea.io/files/fa73af7acc2d3da3b3dcd39757fa9385.mp4", "https://i0.wp.com/www.printmag.com/wp-content/uploads/2021/02/4cbe8d_f1ed2800a49649848102c68fc5a66e53mv2.gif?fit=476%2C280&ssl=1", asset["image_url"]][asset["token_id"] % 3]
  }, [asset]);

  if (isLoading) {
    return <Typography variant="h1">Loading...</Typography>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Paper>
          <Box p={4} textAlign="center">
            {imageSource.includes("mp4") ? (
              <video width="100%" src={imageSource} controls />
            ) : (
              <img src={imageSource} style={{ maxWidth: "100%" }} />
            )}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper>
          <Box p={4} mb={2}>
            <Typography variant="h1" gutterBottom>{asset["name"]}</Typography>
            <Typography variant="h3">Description</Typography>
            <Typography variant="h5" gutterBottom>{asset["description"]}</Typography>
          </Box>
        </Paper>
        <Paper>
          <Box p={4} mb={2}>
            <Typography variant="h3" gutterBottom>Properties</Typography>
            <Grid container spacing={2}>
            {
              properties.map((traitName) => {
                const trait = traits[traitName];
                if (trait) {
                  return (
                    <Grid item xs={4} minWidth="200px" key={traitName}>
                      <Box border="2px solid white" borderRadius="15px" p={2} textAlign="center">
                        <Typography>{toTitleCase(trait["trait_type"])}</Typography>
                        <Typography variant="h4">{trait["display_value"] || trait["value"]}</Typography>
                      </Box>
                    </Grid>
                  )
                }
                return null;
              })
            }
            </Grid>
          </Box>
        </Paper>
        <Paper>
          <Box p={4} mb={2}>
            <Typography variant="h3" gutterBottom>Stats</Typography>
            {
              stats.map((traitName) => {
                const trait = traits[traitName];
                if (trait) {
                  return (
                    <Box key={traitName} display="flex" justifyContent="space-between">
                      <Typography variant="h4">{toTitleCase(trait["trait_type"])}:</Typography>
                      <Typography variant="h4" gutterBottom>{trait["display_value"] || trait["value"]}</Typography>
                    </Box>
                  );
                }
                return null;
              })
            }
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ItemDetails;