import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ethers } from "ethers"
import { useConnectWallet } from "./ConnectWalletContext";
import { useContracts, ContractName } from "./ContractsContext";
import { useErrorNotification } from "./ErrorNotificationContext";

export interface RaceGameProps {
  refresh: () => void;
  refreshRecord: () => void;
  resetClaim: () => void;
  burnAndClaim: (amount: number) => void;
  record: number;
  remaining: number;
  claimStage: number;
  claimSuccess: boolean;
  claimFailed: boolean;
};

export const RaceGameContext = createContext<RaceGameProps>({
  refresh: () => {},
  refreshRecord: () => {},
  resetClaim: () => {},
  burnAndClaim: (amount: number) => {},
  record: 0,
  remaining: 0,
  claimStage: 0,
  claimSuccess: false,
  claimFailed: false,
});

export const useRaceGame = () => {
  const context = useContext(RaceGameContext);

  return context;
};

const initialState = {
  record: 0,
  remaining: 0,
  claimStage: 0,
  claimSuccess: false,
  claimFailed: false,
};

const reducer = (state, { type, ...actionProps }) => {
  switch (type) {
    case "init": {
      return {
        ...initialState,
        ...actionProps,
      };
    }
    case "update": {
      return {
        ...state,
        ...actionProps,
      }
    }
    case "claimStart": {
      return {
        ...state,
        claimStage: 1,
      }
    }
    case "claimSuccess": {
      return {
        ...state,
        claimStage: 2,
        claimSuccess: true,
      }
    }
    case "claimFailed": {
      return {
        ...state,
        claimStage: 2,
        claimFailed: true,
      }
    }
    case "claimReset": {
      return {
        ...state,
        claimStage: 0,
        claimSuccess: false,
        claimFailed: false,
      }
    }
    default: {
      return state;
    }
  }
};

export const RaceGameProvider: React.FC = ({ children }) => {
  const {
    [ContractName.RaceGameTokenVendor]: contract,
    [ContractName.GAMToken]: gamToken,
  } = useContracts();
  const { address } = useConnectWallet();
  const { addError } = useErrorNotification();
  const [state, dispatch] = useReducer(reducer, initialState);

  const refreshRecord = React.useCallback(
    async () => {
      try {
        const record = parseInt(ethers.utils.formatEther(await contract.recordOf(address)));
        dispatch({
          type: "update",
          record,
        });
      } catch (e) {
        console.log(e);
        addError({
          label: e.data?.message || e.message,
        });
      }
    },
    [address, contract, addError]
  )

  useEffect(() => {
    if (address && contract) {
      refreshRecord();
    }
  }, [address, contract, addError]);

  const burnAndClaim = React.useCallback(
    async (amount: number) => {
      try {
        dispatch({
          type: "claimStart",
        });
        const gamAmount = ethers.utils.parseEther(`${amount}`);
        const approveGam = await gamToken.approve(contract.address, gamAmount);
        await approveGam.wait();
        const tx = await contract.burnAndClaim(gamAmount);
        await tx.wait();
        dispatch({
          type: "claimSuccess",
        });
      } catch (e) {
        console.log(e);
        addError({
          label: e.data?.message || e.message,
        });
        dispatch({
          type: "claimFailed",
        });
      }
    },
    [contract, gamToken, addError]
  );

  const refresh = React.useCallback(
    async () => {
      try {
        const remaining = parseFloat(ethers.utils.formatEther(await contract.remainingBalance()));
        dispatch({
            type: "update",
            remaining,
        });
      } catch (e) {
        console.log(e);
        addError({
          label: e.data?.message || e.message,
        });
      }
    },
    [contract, addError]
  );

  const resetClaim = React.useCallback(
    () => {
      dispatch({
        type: "claimReset",
      });
    },
    []
  );

  const value = React.useMemo(() => {
    return { ...state, burnAndClaim, refresh, refreshRecord, resetClaim };
  }, [state, burnAndClaim, refresh, refreshRecord, resetClaim]);

  return (
    <RaceGameContext.Provider value={value}>
      {children}
    </RaceGameContext.Provider>
  );
}
