import { Button, Box } from "@mui/material";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import { useTranslation } from "react-i18next";
import { colors } from '@utils/Constants'
import styled, { css } from 'styled-components';

const ConnectWalletStyledButton = styled(Button)`
  border-color: ${colors.white};
  color: ${colors.paleWhite};
  border-radius: 99px;
  padding: 6px 20px;
`

const ConnectWalletButton = () => {
  const { t } = useTranslation();
  const { connect, address, disconnect } = useConnectWallet();
  
  if (address) {
    return (
      <ConnectWalletStyledButton
        color="info"
        role="button"
        variant="contained"
        aria-label="disconnect"
        onClick={disconnect}
        size="small"
        sx={{
          backgroundColor: "transparent",
          borderColor: colors.paleWhite,
          color: colors.white,
          p: (theme) => theme.spacing(0.5, 1),
            fontSize: "overline.fontSize",
            width: {
              xs: 150,
            },
            minWidth: {
              xs: 150,
              sm: 200,
            },
          }
        }
      >
        <Box
          p={0}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {address}
        </Box>
      </ConnectWalletStyledButton>
    );
  }
  return (
    <ConnectWalletStyledButton
      color="info"
      role="button"
      variant="outlined"
      aria-label="connect"
      onClick={connect}
      sx={{
        backgroundColor: "transparent",
        borderColor: colors.paleWhite,
        color: colors.white,
      }}
    >
      {t("wallet:connect")}
    </ConnectWalletStyledButton>
  );
};
export default ConnectWalletButton;
