import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConnectWalletButton from "./ConnectWalletButton";
import { useConnectWallet } from "@contexts/ConnectWalletContext";

const WalletRequiredPrompt = () => {
  const { t } = useTranslation();
  const { address } = useConnectWallet();

  if (address) return null;
  return (
    <Box component={Paper} p={2} textAlign="center">
      <Box mb={2}>
        <Typography gutterBottom>
          {t("wallet:connect.require.title")}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {t("wallet:connect.require.remarks")}
        </Typography>
      </Box>
      <ConnectWalletButton />
    </Box>
  );
};
export default WalletRequiredPrompt;
