import React, { useCallback } from "react";
import Greeting from "../containers/wallet/Greeting";
import styled, { css } from 'styled-components'
import { Box, Button, Container, Grid, Paper, Typography, Link, Stack } from "@mui/material";
import ComingSoonOverlay from "@components/shared/ComingSoonOverlay";
import SocialMediaBanner from "@components/shared/SocialMediaBanner";
import ListNotification from "@containers/dashboard/ListNotification";
import ListRecentTrade from "@containers/dashboard/ListRecentTrade";
import Hidden from "@mui/material/Hidden";
import PriceChart from '@components/widgets/PriceChart'
import DiscordWidget from "@components/widgets/DiscordWidget";
import TwitterRSSWidget from "@components/widgets/TwitterRSSWidget";
import { colors } from '@utils/Constants'
import Skeleton from '@mui/material/Skeleton';
import Title from '@components/shared/Title'
import Banner from "@components/shared/Banner";
import { useHistory } from "react-router-dom";

const IconBackground = styled(Box)`
  position: "absolute";
  top: 0;
  left: 0;
  border-radius: 99px;
  padding: 4px;
  width: 48px;
  height: 48px;
  opacity: 0.5;
`

const ButtonLeftIconWrapper = styled(Box)`
  position: relative;
  height: 48px;
  width: 48px;
  margin-right: 12px;
`

const SectionTitle = styled(Typography)`
  font-weight: bold;
`
const SwapButton = styled(Button)`
  border-radius: 99px;
  padding: 0px 38px 0px 6px; 
  height: 60px;
`

const PancakeExchangeButton = styled(SwapButton)`
  background-color: ${colors.palePurpleClearColor};
  &:hover {
    background-color: ${colors.palePurpleHoverColor};
  }
`

const InchExchangeButton = styled(SwapButton)`
  background-color: ${colors.paleBrownClearColor};
  &:hover {
    background-color: ${colors.paleBrownHoverColor};
  }
`

const PancakeSwapButton = styled(SwapButton)`
  background-color: ${colors.paleBlueClearColor};
  &:hover {
    background-color: ${colors.paleBlueHoverColor};
  }
`

const ButtonImageIcon = styled.img`
  z-index: 2; 
  height: 32px; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
`

const OutlinedPaperBox = styled(Box)`
  border: 1px solid ${colors.gray};
  position: relative;
  min-height: 200px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
`

const ButtonSingleLineTypography = styled(Typography)`
  line-height: 16px;
  text-align: left;
  font-size: 14px;
`

const FollowButton = styled(Button)`
  border-radius: 99px;
  color: #fff;
  margin-left: auto;
  height: 45px;
`

const cardImage = {
  game_demo: "game_demo.png",
  battle: "battle_events.png",
  marketplace: "marketplace.png",
};
const ComingSoonFeatureCard = ({
  label,
  feature,
}: {
  label: React.ReactNode;
  feature: string;
}) => {
  return (
    <Box
      component={Paper}
      sx={{
        flexGrow: 1,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url("./assets/${cardImage[feature]}")`,
      }}
      p={2}
      position="relative"
      minHeight={250}
    >
      <ComingSoonOverlay />
      <Typography>{label}</Typography>
    </Box>
  );
};


const Dashboard = () => {
  const history = useHistory();

  const onFollowBtnClick = useCallback((type) => {
    let url = type === 'twitter' ? 'https://twitter.com/MetaWarsNFT?ref_src=twsrc%5Etfw' : 'https://discord.gg/metawars'
    window.open(url, "_blank")
  }, [])

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          container
          alignContent="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Greeting />
          </Grid>

          <Banner
            img={`${process.env.PUBLIC_URL}/images/metagodsBanner.png`}
            url="https://info-137.gitbook.io/metagods-next-generation-nft-action-rpg-game/dual-token-economy-usdmgod-and-usdrelic/usdmgod"
          />

          <Grid item container xs={12} spacing={2}>

            <Grid item container xs={12} spacing={2} flexDirection="row">

              <Grid container item xs={12} md={8}>
                <Grid item xs={12} container columnGap={1} rowGap={"2px"} pb="6px" alignItems="center">
                  <PancakeExchangeButton variant="outlined" sx={{ borderColor: colors.palePurpleColor, color: colors.purpleColor }} onClick={() => window.open("https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x50e756a22ff5cee3559d18b9d9576bc38f09fa7c")}>
                    <ButtonLeftIconWrapper>
                      <ButtonImageIcon src={`${process.env.PUBLIC_URL}/images/pancakeswap_logo.png`} />
                      <IconBackground sx={{ backgroundColor: colors.palePurpleColor }} />
                    </ButtonLeftIconWrapper>
                    <Stack>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">Pancakeswap</ButtonSingleLineTypography>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">Exchange</ButtonSingleLineTypography>
                    </Stack>
                  </PancakeExchangeButton>
                  <InchExchangeButton variant="outlined" sx={{ borderColor: colors.paleBrownColor, color: colors.brownColor }} onClick={() => window.open("https://app.1inch.io/#/56/swap/BUSD/WARS")}>
                    <ButtonLeftIconWrapper>
                      <ButtonImageIcon src={`${process.env.PUBLIC_URL}/images/1inch_logo.png`} />
                      <IconBackground sx={{ backgroundColor: colors.paleBrownColor }} />
                    </ButtonLeftIconWrapper>
                    <Stack>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">1inch</ButtonSingleLineTypography>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">Exchange</ButtonSingleLineTypography>
                    </Stack>
                  </InchExchangeButton>
                  <PancakeSwapButton variant="outlined" sx={{ borderColor: colors.paleBlueColor, color: colors.blueColor }} onClick={() => window.open("https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c")}>
                    <ButtonLeftIconWrapper>
                      <ButtonImageIcon src={`${process.env.PUBLIC_URL}/images/pancakeswap_logo.png`} />
                      <IconBackground sx={{ backgroundColor: colors.paleBlueColor }} />
                    </ButtonLeftIconWrapper>
                    <Stack>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">Pancakeswap</ButtonSingleLineTypography>
                      <ButtonSingleLineTypography variant="h6" fontWeight="bold">Liquidity</ButtonSingleLineTypography>
                    </Stack>
                  </PancakeSwapButton>
                </Grid>


              </Grid>

            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid container item xs={12} md={7} flexDirection="row" flex={1} gap={2}>
                {
                  // <OutlinedPaperBox component={Paper} p={2} sx={{height: '200px'}}>
                  //   {
                  //     // <ComingSoonOverlay />
                  //   }
                  //   <Box mb={2} sx={{height: "20%"}}>
                  //     <SectionTitle variant="h3" fontWeight="bold" sx={{color: colors.paleRedColor}}>Recent Trades</SectionTitle>
                  //   </Box>
                  //   <Box sx={{padding: '4px', height: '80%', overflow: 'auto'}}>
                  //     <ListRecentTrade />
                  //   </Box>
                  // </OutlinedPaperBox>
                }

                <OutlinedPaperBox component={Paper} p={2}>
                  <Grid container rowGap={2}>
                    <Grid item xs={12}>
                      <DiscordWidget />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-end">
                      <FollowButton variant="contained"
                        onClick={() => onFollowBtnClick("discord")}
                        sx={{
                          '&:hover': {
                            backgroundColor: colors.discordColor,
                          }, backgroundColor: colors.discordColor
                        }}>Follow</FollowButton>
                    </Grid>
                  </Grid>
                </OutlinedPaperBox>
              </Grid>

              <Grid container item xs={12} md={5} flexDirection="row" flex={1} gap={2}>
                <OutlinedPaperBox component={Paper} p={2}>
                  <Grid container rowGap={2} height={"100%"}>
                    <Grid item xs={12}>
                      <TwitterRSSWidget />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-end">
                      <FollowButton variant="contained"
                        onClick={() => onFollowBtnClick("twitter")}
                        sx={{
                          '&:hover': {
                            backgroundColor: colors.twitterColor,
                          }, backgroundColor: colors.twitterColor
                        }}>Follow</FollowButton>
                    </Grid>
                  </Grid>
                </OutlinedPaperBox>

              </Grid>
            </Grid>


          </Grid>


        </Grid>

        {
          // <Hidden smUp>
          //   <Grid item xs={12}>
          //     <SocialMediaBanner />
          //   </Grid>
          // </Hidden>
        }

      </Grid>


    </Container>
  );
};
export default Dashboard;
