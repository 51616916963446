import { useState, useEffect } from "react"
import { Box, Button, Container, Typography } from "@mui/material"
import axios from "axios"
import Greeting from "@containers/wallet/Greeting"
import ConnectWalletButton from "@containers/wallet/ConnectWalletButton"
import { useConnectWallet } from "../../contexts/ConnectWalletContext"
import { useQuery } from "@utils/helpers"

enum SuccessState {
  NONE = "",
  SUCCESS = "success",
  FAILED = "failed",
}

const VerificationContainer = () => {
  const { address, isConnected } = useConnectWallet()
  const query = useQuery()
  const [successState, setSuccessState] = useState<SuccessState>(SuccessState.NONE)
  const discordId = query.get("discordID")
  console.log(discordId)

  useEffect(() => {
    if (!isConnected) {
      setSuccessState(SuccessState.NONE)
    }
  }, [isConnected])

  const handleVerificate = async () => {
    try {
      await axios.post("/discord/verification/mgod", { // process.env["REACT_APP_DISCORD_VERIFY"]!, {
        address,
        discordId,
      }, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      setSuccessState(SuccessState.SUCCESS)
    } catch (err) {
      console.log("Validation Err: ", err)
      setSuccessState(SuccessState.FAILED)
    }
  }

  let content
  switch (successState) {
    case SuccessState.NONE:
      content = 
        isConnected ? (
          <Box display="flex" flexDirection="column" rowGap={5}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography fontSize={{ xs: "3rem", lg: "4rem" }} textAlign="center">Please confirm to verify</Typography>
              <Typography fontSize={{ xs: "3rem", lg: "4rem" }} textAlign="center">your Wallet Address.</Typography>
              <Typography fontSize="1.5rem">{address}</Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" onClick={handleVerificate}>
                <Typography>Verificate</Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" rowGap={5} alignItems="center">
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Typography fontSize={{ xs: "2.5rem", md: "3rem", lg: "4rem" }} textAlign="center">Please connect your MetaMask</Typography>
              <Typography fontSize={{ xs: "2.5rem", md: "3rem", lg: "4rem" }} textAlign="center">Wallet for verification.</Typography>
            </Box>
            <ConnectWalletButton />
          </Box>
        )
      break
    case SuccessState.SUCCESS:
      content =
        <Typography fontSize={{ xs: "3rem", lg: "4rem" }} textAlign="center">Verify Successfully</Typography>
      break
    case SuccessState.FAILED:
      content =
          <Box display="flex" flexDirection="column" alignItems="center" rowGap={5}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography fontSize={{ xs: "3rem", lg: "4rem" }} textAlign="center">Verify Failed</Typography>
              <Typography fontSize="2rem">Please Try Again</Typography>
            </Box>
            <Button variant="contained" onClick={() => setSuccessState(SuccessState.NONE)}>
              <Typography>Back</Typography>
            </Button>
          </Box>
      break
    default:
      break;
  }

  return (
    <Container>
      <Box height="100vh" display="flex" flexDirection="column" style={{ overflowY: "hidden" }}>
        <Greeting />
        <Box display="flex" flexGrow={1} flexDirection="column" justifyContent="center" alignItems="center">
          {content}
        </Box>
      </Box>
    </Container>
  )
}

export default VerificationContainer