import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import WalletRequiredPrompt from "../../containers/wallet/WalletRequiredPrompt";
import Menu from "./Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import LogoLink from "./LogoLink";
import { colors } from '@utils/Constants'
const drawerWidth = 290;
const halfTransparentBg = "rgba(0, 0, 0, 0.5)"

const MobileFrame: React.FC = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
      }}
    >
      <Toolbar sx={{ pt: 2 }}>
        <LogoLink />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ ml: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiPaper-root": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#000",
            display: "flex",
            flexDirection: "column",
          },
        }}
        open={open}
        onClose={handleDrawerToggle}
        variant="temporary"
        anchor="left"
      >
        <Box flex={1}>
          <Menu handleDrawerToggle={handleDrawerToggle}/>
        </Box>
        <div>
          <WalletRequiredPrompt />
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, overflow: "auto", p: 2, height: "100vh" }}>
        <Box pb={4}>{children}</Box>
      </Box>
    </Box>
  );
};
export default MobileFrame;
