import rssEnhancer from 'react-rss'
import TweetEmbed from 'react-tweet-embed'
import Twitter from '@mui/icons-material/Twitter'
import { IconButton, Typography, Link, Stack, Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton';
import { colors } from '@utils/Constants'
import { useCallback, useState } from 'react';

const TwitterRSSWidget = (props) => {
  const { rss } = props
  const idRegex = /(?<=\/status\/)(\d+)/;

  const [noOfTweetLoaded, setNoOfTweetLoaded] = useState(0)

  const onTweetLoadSuccess = useCallback(() => {
    setNoOfTweetLoaded(prevCnt => ++prevCnt)
  }, [])

  return (
    <Box>

      <Box sx={{display: "inline-flex"}}>
        <Link href={rss.header.link} target='_blank'>
            <IconButton sx={{color: colors.twitterColor }}>
              <Twitter />
            </IconButton>
          </Link>
        <Typography variant="h3" sx={{ fontSize: "18px", margin: 'auto auto auto 10px', color: colors.twitterColor, fontWeight: 'bold'}}>
          Twitter
        </Typography>
      </Box>

      <Box style={{ height: '710px', paddingRight: '2px', overflow: 'auto', position: 'relative' }}>
        {
          noOfTweetLoaded < rss.items.length && <Box sx={{width: '100%', height: '330px'}}>
            <Skeleton variant="rectangular" height={"330px"} sx={{borderRadius: '10px'}}/>
          </Box>
        }
        {
          rss.items.map((item) => (
            <TweetEmbed onTweetLoadSuccess={onTweetLoadSuccess} key={item.title} id={item.description.match(idRegex)[0]} />
          ))
        }
      </Box>
    </Box>
  )
}

export default rssEnhancer(
  TwitterRSSWidget,
  'https://rss.app/feeds/pPrgsdnewfVg9T5v.xml'
)