import Greeting from "../containers/wallet/Greeting";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LotteryProvider } from "@contexts/LotteryContext";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import LotteryPanel from "@containers/lottery/LotteryPanel";
import InstructionScreen from "@components/shared/InstructionScreen";
import Title from "@components/shared/Title";

const Lottery = () => {
  const { t } = useTranslation("lottery");
  const { isConnected } = useConnectWallet();
  return (
    <LotteryProvider>
      <Container maxWidth="lg" sx={{ marginLeft: 0 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item container xs={12} justifyContent="space-between">
            <Title name={t("title")} hasBanner={false}/>
          </Grid>

        </Grid>
      </Container>
      <Container maxWidth="lg" sx={{ ml: 0, minWidth: "800px" }}>
        <LotteryPanel />
      </Container>
    </LotteryProvider>
  );
};
export default Lottery;
