import { useCallback, useMemo } from "react";
import { Box, Card, CardContent, CardMedia, Typography, Divider, Button, Grid, Link, styled } from "@mui/material";
import { NFTMeta } from "../../types/nftMeta";
import { toTitleCase } from '../../utils/valueDisplayHelper';
import { colors, PLANET_POWER } from '@utils/Constants'
import { useHistory } from 'react-router-dom'
import Path from "@routes/path";
import TwitterIcon from '@mui/icons-material/Twitter'
import { useTranslation } from "react-i18next";

type NFTCardProps = {
  meta: NFTMeta,
  traitsDisplayOrder: string[]
}

const StyledActionButton = styled(Button)`
  color: ${colors.paleWhite};
  border-radius: 20px;
  border: 1px solid ${colors.paleWhite};
  text-transform: unset;
  width: 160px;
  @media(max-width: 1248px) {
    width: 100% !important;
    margin: 0px;
  }
`

const NFTCard = (props: NFTCardProps) => {
  const { meta, traitsDisplayOrder } = props;
  const traits = meta.attributes;
  const { t } = useTranslation("planetDetail")
  const history = useHistory();

  const onPlanetClick = useCallback(() => {
    history.push({
      pathname: `${Path.PlanetDetail.Root}/${meta?.tokenId}`,
      state: { meta }
    })
  }, [meta])

  const onLootboxClick = useCallback(() => {
    history.push({
      pathname: `${Path.MachDetail.Root}/${meta?.tokenId}`,
      state: { meta }
    })
  }, [meta])

  const onRevealBtnClick = useCallback(() => {
    history.push({
      pathname: `${Path.PlanetDetail.Root}/${meta?.tokenId}`,
      state: { meta, fromRevealButton: true }
    })
  }, [meta])

  return (
    <Card sx={{
      height: "100%", borderRadius: "10px", cursor: "pointer",
      border: `1px solid ${colors.gray}`, padding: '8px', backgroundColor: "transparent",

    }}>
      <Box pb="100%" sx={{
        position: "relative",
        '&:hover': {
          opacity: 0.8,
        }
      }} onClick={meta.isPlanet ? onPlanetClick : meta.isLootbox ? onLootboxClick : undefined}>
        {meta.image_url?.includes(".mp4") ? (
          <CardMedia
            component="video"
            autoPlay
            muted
            loop
            src={meta.image_url || "/images/metawars.png"}
            sx={{ position: "absolute", height: "95%", width: "81%", left: "10%", top: "3%", objectFit: "contain" }}
          />
        ) : (
          <CardMedia
            component="img"
            src={meta.image_url || "/images/metawars.png"}
            alt="nft-card-image"
            sx={{ objectFit: "contain", position: "absolute", height: "92%", width: "100%", top: "7%" }}
          />
        )}
        {meta.isPlanet && traits["Rarity"] ? (
          <Box position="absolute" width="100%" height="100%">
            <img width="100%" height="100%" src={`${process.env.PUBLIC_URL}/assets/rarity_border/rarity_${traits["Rarity"].value.replace(' ', '_')}.png`} />
          </Box>
        ) : null}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Divider sx={{ width: '94%', backgroundColor: colors.gray }} />
      </Box>

      <CardContent sx={{ paddingTop: "24px" }}>
        <Typography sx={{ fontWeight: "Jura" }} variant="h5" fontWeight="bold"
          textAlign="center" fontFamily="jura">{meta.name}</Typography>
        {traitsDisplayOrder.map((traitName) => {
          const trait = traits[traitName];
          return trait ? (
            <Box key={trait.trait_type} display="flex" justifyContent="space-between">
              {/* <Typography>{toTitleCase(trait.trait_type)}:</Typography> */}
              {trait.trait_type === "Rarity" ? (
                <Typography fontFamily="jura">Power: {PLANET_POWER[trait.value]}</Typography>
              ) : (
                <Typography>{trait.display_value || trait.value}</Typography>
              )}
            </Box>
          ) : null
        })}
      </CardContent>

      <Grid item container xs={12} justifyContent="center" pt="8px" pb="14px">
        {
          meta.isPlanet && traits.length > 1 ? <Button sx={{
            position: "relative",
            backgroundColor: colors.twitterBlue,
            padding: "2px 8px",
            "&:hover": {
              backgroundColor: colors.twitterHoverBlue
            }
          }}>
            <Box display="flex">
              <TwitterIcon sx={{ color: colors.white }} />
              <Link
                href={`https://twitter.com/intent/tweet?text=This%20is%20my%20PLANET%21%20%F0%9F%AA%90%0ARetweet%20to%20gain%20reward%20%21%F0%9F%A4%A9%0A%23MWARSPlanetReveal%0A%40MetaWarsNFT%0ASomething%20BIG%20is%20coming%20%F0%9F%91%89%20${process.env.REACT_APP_SHOWCASE_API}${meta.tokenId}`}
                target="_blank" color={colors.white} sx={{ lineHeight: "unset" }}>
                &nbsp;Share
              </Link>
            </Box>
          </Button> : null
        }
        {
          meta.isPlanet && traits.length === 1 ? <StyledActionButton sx={{
            position: "relative",
            padding: "2px 8px",
            color: colors.white,
            background: `linear-gradient(90deg, ${colors.orangeColor} 0%, ${colors.paleRedColor} 100%)`,
          }}
            onClick={onRevealBtnClick}
          >
            {t("reveal")}
          </StyledActionButton> : null
        }
      </Grid>
    </Card>
  );
};

export default NFTCard;
