import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontSize: 16,
    
    fontFamily: ["Poppins", "Jura", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1.125rem",
    },
    h6: {
      fontSize: "1.125rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    overline: {
      fontSize: "0.875rem",
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#18181d",
    },
    primary: {
      main: "#54c1fb",
    },
    secondary: {
      main: "#fe9812",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            '&:hover': {
              opacity: 0.8,
              borderColor: 'unset'
            },
            fontFamily: ["Jura", "Poppins", "Arial", "sans-serif"].join(","),
          }
        },
        {
          props: { variant: "contained" },
          style: {
            '&:hover': {
              opacity: 0.8,
            },
            borderRadius: 32,
            minWidth: 200,
            fontFamily: ["Jura", "Poppins", "Arial", "sans-serif"].join(","),
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            '&:hover': {
              opacity: 0.8,
            },
            background: "#54c1fb",
          },
        },
        {
          props: { variant: "contained", color: "info" },
          style: {
            '&:hover': {
              opacity: 0.8,
            },
            background: "#FFF",
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "*::-webkit-scrollbar": {
            width: ".5em",
            height: ".5em"
          },
          "*::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: 10,
          },
        },
      },
    },
  },
});
export default theme;
