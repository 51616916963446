import { Switch, Route, Redirect } from "react-router-dom";
import Path from "./path";
import Dashboard from "../pages/Dashboard";
import Staking from "../pages/Staking";
import Vesting from "../pages/Vesting";
import Inventory from "../pages/Inventory";
import InventoryItem from "../pages/InventoryItem";
import Lottery from "../pages/Lottery";
import Fusion from "../pages/Fusion";
import PlanetFi from "../pages/PlanetFi";
import PlanetFusion from "../pages/PlanetFusion";
import RaceGame from "../pages/RaceGame";
import PlanetDetail from "pages/PlanetDetail";
import MachDetail from "pages/MachDetail";
import Verification from "pages/Verification";

const routes = [
  {
    path: Path.Dashboard.Root,
    exact: true,
    component: Dashboard,
  },
  {
    path: Path.Staking.Root,
    exact: true,
    component: Staking,
  },
  {
    path: Path.Vesting.Root,
    exact: true,
    component: Vesting,
  },
  {
    path: Path.Inventory.Root,
    exact: true,
    component: Inventory,
  },
  {
    path: Path.Inventory.Item,
    exact: false,
    component: InventoryItem
  },
  {
    path: Path.Lottery.Root,
    exact: true,
    component: Lottery,
  },
  {
    path: Path.Fusion.Root,
    exact: true,
    component: PlanetFusion,
  },
  {
    path: Path.PlanetFi.Root,
    exact: true,
    component: PlanetFi,
  },
  {
    path: Path.RaceGame.Root,
    exact: true,
    component: RaceGame,
  },
  {
    path: Path.PlanetDetail.Item,
    exact: true,
    component: PlanetDetail,
  },
  {
    path: Path.MachDetail.Item,
    exact: true,
    component: MachDetail,
  },
  {
    path: Path.Verification.Root,
    exact: true,
    component: Verification,
  },
];

const Routes = () => {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <Redirect to={Path.Dashboard.Root} />
    </Switch>
  );
};
export default Routes;
