import React, { useCallback } from "react";
import Greeting from "@containers/wallet/Greeting";
import { Box, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Title from "@components/shared/Title";
import Banner from "@components/shared/Banner";

const PlanetFi = () => {
  const { t } = useTranslation("planetFi");

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item xs={12} justifyContent="flex-start">
            <Title name={"PlanetFi"} hasBanner={false} />
          </Grid>

          <Grid item container xs={12} rowGap={2}>
            <Banner
              img={`${process.env.PUBLIC_URL}/images/metagodsBanner.png`}
              url="https://info-137.gitbook.io/metagods-next-generation-nft-action-rpg-game/dual-token-economy-usdmgod-and-usdrelic/usdmgod"
            />
            {/* <Banner
              img={`${process.env.PUBLIC_URL}/images/fitevoBanner.png`}
            />
            <Banner
              img={`${process.env.PUBLIC_URL}/images/ttkBanner.png`}
            /> */}
            <Banner
              img={`${process.env.PUBLIC_URL}/images/mgodLandsaleBanner.jpeg`}
              // url="https://app.metagods.gg/bossmap"
              grayOut
            />
          </Grid>

        </Grid>
      </Box>
    </Container>
  );
};
export default PlanetFi;
