import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { colors } from '@utils/Constants'

const ExploreButton = styled(Button)`
  background: linear-gradient(90deg, ${colors.orangeColor} 0%, ${colors.paleRedColor} 100%);
  color: white;
`;

const ExploreMoreCard = (props) => {
  const history = useHistory();
  
  const handlePlanetRaffleButton = () => {
    history.push("/planet-nft-sale");
  }

  const handleMarketplaceButton = () => {
    window.open("https://nftrade.com/assets/bsc/0x1f099dafba907498e701a72da0007d58f88459de");
  }

  return (
    <Card sx={{ borderRadius: "10px", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: colors.paleBrownClearColor, border: `1px solid ${colors.orangeColor}`, height: "100%" }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Box display="flex" flexDirection="column" rowGap="30px">
          <Typography variant="h2" fontWeight="bold" color={colors.orangeColor} gutterBottom>Explore More</Typography>
          <ExploreButton variant="contained" onClick={handlePlanetRaffleButton}>Planet Raffle</ExploreButton>
          <Typography variant="h4" fontWeight="bold">OR</Typography>
          <ExploreButton variant="contained" onClick={handleMarketplaceButton}>
            To NFTrade
            <img
              src={`${process.env.PUBLIC_URL}/images/nftrade_logo.png`}
              style={{ height: "18px", paddingLeft: "8px" }}
            />
          </ExploreButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExploreMoreCard;
