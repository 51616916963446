import React, { useCallback, useEffect, useState } from "react";
import Greeting from "@containers/wallet/Greeting";
import { styled, Box, Button, Container, Grid, Typography, Skeleton, Link } from "@mui/material";
import SocialMediaBanner from "@components/shared/SocialMediaBanner";
import AvailableToClaim from "@containers/vesting/AvailableToClaim";
import ClaimedAmount from "@containers/vesting/ClaimedAmount";
import ClaimVesting from "@containers/vesting/ClaimVesting";
import VestingProgress from "@containers/vesting/VestingProgress";
import { useTranslation } from "react-i18next";
import Title from "@components/shared/Title";
import FusionImg from '../images/fusion800.png'
import { colors } from "@utils/Constants";
import { useErrorNotification } from "@contexts/ErrorNotificationContext";
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import Path from "@routes/path";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useConnectWallet } from "@contexts/ConnectWalletContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material';
import usePlanets from "@hooks/usePlanets";
import useLootboxes from "@hooks/useLootboxes";

const StyledActionButton = styled(Button)`
  color: ${colors.white};
  border-radius: 20px;
  border: 1px solid ${colors.paleWhite};
  text-transform: unset;
  width: 160px;
  &:hover{
    opacity: 0.7;
  }
  @media(max-width: 1248px) {
    width: 100% !important;
    margin: 0px;
  }
`

const OutlinedGrid = styled(Grid)`
  border: 1px solid ${colors.dimGray};
  border-radius: 10px;
  // padding: 20px;
`

const LabelTypography = styled(Typography)`
  padding-right: 10px;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: Jura;
  font-weight: bold !important;
`

const RowLabelTypography = styled(Typography)`
  color: ${colors.dimGray};
  text-transform: uppercase;
  font-size: 14px;
  padding-top: 4px;
`

const ValueTypography = styled(Typography)`
  color: ${colors.white};
  font-family: Jura;
  font-weight: bold !important;
`

const UnderlinedGrid = styled(Grid)`
  border-bottom: 1px solid ${colors.dimGray};
  justify-content: space-between;
  padding: 0px 6px;
  height: 40px;
  align-items: center;
`

const InfoRowGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 26px 0px 26px;
`
const InfoRowGridCell = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 40px;
`

const RowValueTypography = styled(Typography)`
  width: 100%;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
`

type MechDetailType = {
  name?: string;
  image?: string;
  description?: string;
  animation_url?: string;
  attributes?: any[];
  mechType?: string,
  mechName?: string,
  eye?: string,
  colors?: string,
  pattern?: string,
  background?: string,
  exploitation?: string,
  bluePrint?: string,
}


const MechDetail = (props) => {
  const { t } = useTranslation("machDetail");
  const { addError } = useErrorNotification();
  const location = useLocation()
  const history = useHistory()
  const [mechDtl, setMechDtl] = useState<MechDetailType>({
    name: "",
    image: "",
    description: "",
    animation_url: "",
    attributes: [],
    mechType: "",
    mechName: "",
    eye: "",
    colors: "",
    pattern: "",
    background: "",
    exploitation: "",
    bluePrint: ""

  })
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const fromRevealButton = location?.state?.fromRevealButton
  let tokenId = props?.match?.params?.tokenId

  const onNFTradeBtnClick = useCallback(() => {
    if (tokenId) {
      window.open(`https://nftrade.com/assets/bsc/0x78Ff0ac0df21b654E6Ab570A988eaDbD22214583/${tokenId}`);
    }
  }, [tokenId])

  const extractProcessMetadata = (tokenInfoRes) => {
    let attrs = tokenInfoRes.data?.attributes
    let mechType = attrs.find((e) => e.trait_type === "Mech Type")?.value
    let mechName = attrs.find((e) => e.trait_type === "Mech Name")?.value
    let eye = attrs.find((e) => e.trait_type === "Eye Laser Color")?.value
    let colors = attrs.find((e) => e.trait_type === "Colors")?.value
    let pattern = attrs.find((e) => e.trait_type === "Pattern")?.value
    let background = attrs.find((e) => e.trait_type === "Background")?.value
    let exploitation = attrs.find((e) => e.trait_type === "Exploitation")?.value
    let bluePrint = attrs.find((e) => e.trait_type === "BluePrint")?.value

    setMechDtl({
      ...tokenInfoRes.data,
      mechType,
      mechName,
      eye,
      colors,
      pattern,
      background,
      exploitation,
      bluePrint
    })
  }

  useEffect(() => {

    return () => {
      history.replace({ ...history.location });
    }
  }, [history])

  useEffect(() => {
    if ([null, undefined].includes(tokenId)) {
      history.push(Path.Dashboard.Root)
    }

    const run = async () => {
      try {
        let tokenInfoRes = await axios.get(`${process.env.REACT_APP_MECH_INFO}${tokenId}`)
        if (tokenInfoRes.status === 200) {
          extractProcessMetadata(tokenInfoRes)
        }
      } catch (err) {
        console.log(err)
      }


    }
    run()
  }, [tokenId])

  const onBackBtnClick = useCallback(() => {
    history.push(Path.Inventory.Root)
  }, [])

  return (
    <Container
      maxWidth="lg"
      sx={{ marginLeft: 0, height: "100%", display: "flex" }}
    >
      <Box flex={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          <Grid item xs={12} justifyContent="flex-start">
            <Title name={"Mech Detail"} hasBanner={true} />
          </Grid>

          {
            <Grid item container xs={12} rowGap={"10px"}>

              <OutlinedGrid item container xs={12} lg={6} position={"relative"}>
                <Box
                  onClick={onBackBtnClick}
                  sx={{
                    position: "absolute",
                    top: "20px", left: "20px",
                    cursor: "pointer",
                    borderRadius: "99px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    width: "40px",
                    height: "40px",
                    border: '1px solid #fff'
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#fff" }} />
                </Box>

                <Grid item container xs={12} pt="80px" minHeight="400px" justifyContent={"center"}>
                  {
                    mechDtl?.image ? <img src={mechDtl?.image} style={{ width: "70%" }} /> :
                      <Skeleton variant="rectangular" sx={{ width: "70%", height: "400px", borderRadius: "20px" }} />
                  }
                </Grid>
                <Grid item container xs={12} justifyContent={"center"} py="20px" sx={{
                  borderBottom: `1px solid ${colors.dimGray}`
                }}>
                  <Typography variant="h3" textAlign={"center"} sx={{ color: colors.orangeColor }}>{mechDtl?.name}</Typography>
                </Grid>

                {
                  <Grid item container xs={12} columnSpacing={"6px"} rowSpacing={"6px"} p="20px" justifyContent={"center"}>

                    <StyledActionButton sx={{
                      background: `linear-gradient(90deg, ${colors.orangeColor} 0%, ${colors.paleRedColor} 100%)`,
                      color: colors.white,
                    }} onClick={onNFTradeBtnClick}>
                      {t("nfTrade")}
                    </StyledActionButton>

                  </Grid>
                }

              </OutlinedGrid>

              <Grid item container alignContent="flex-start" xs={12} lg={6} sx={{
                padding: "30px"
              }}>
                <Grid item container xs={12} height="60px">
                  <Typography variant="h2" fontWeight="bold" color={colors.paleRedColor}>{t("attributes")}</Typography>
                </Grid>

                {
                  <Grid item container xs={12} height="200px">
                    {
                      (mechDtl?.mechType && mechDtl?.mechType !== "Unknown") && <UnderlinedGrid item container xs={12} justifyContent={"flex-start"}>
                        <LabelTypography variant="h4">{t("mechType")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.mechType}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.mechName && mechDtl?.mechName !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("mechName")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.mechName}</ValueTypography>
                      </UnderlinedGrid>

                    }
                    {
                      (mechDtl?.colors && mechDtl?.colors !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("colors")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.colors}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.pattern && mechDtl?.pattern !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("pattern")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.pattern}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.eye && mechDtl?.eye !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("eye")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.eye}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.background && mechDtl?.background !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("background")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.background}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.exploitation && mechDtl?.exploitation !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("exploitation")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.exploitation}</ValueTypography>
                      </UnderlinedGrid>
                    }
                    {
                      (mechDtl?.bluePrint && mechDtl?.bluePrint !== "Unknown") && <UnderlinedGrid item container xs={12}>
                        <LabelTypography variant="h4">{t("bluePrint")}</LabelTypography>
                        <ValueTypography variant="h4">{mechDtl?.bluePrint}</ValueTypography>
                      </UnderlinedGrid>
                    }

                  </Grid>
                }

              </Grid>
            </Grid>
          }

        </Grid>
      </Box>
    </Container>
  );
};
export default MechDetail;
