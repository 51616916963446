import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const LotteryDrawButton = (props) => {
  const { disabled, quantity, onClick = () => {} } = props;
  const { t } = useTranslation("lottery");

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: "100%",
        height: "100%",
        "&:hover": {
          bgcolor: "transparent"
        }
      }}
    >
      <img src={`${process.env.PUBLIC_URL}/assets/btn_planet${quantity}.png`} width="100%" style={{ objectFit: "contain" }} />
  </Button>
  );
};

export default LotteryDrawButton;