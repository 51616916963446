import React from "react";
import {
  StakingActionType,
  StakingActiveEvents,
} from "@contexts/StakingContext";
import { StakingPool } from "../../types/staking";
import PoolCardButton from "./PoolCardButton";
import StakeDialogForm from "./StakeDialogForm";
import { useTranslation } from "react-i18next";

const PoolCardStaking = ({
  pool,
  disabled = false,
  onStake,
  activeEvents,
}: {
  pool: StakingPool;
  disabled?: boolean;
  onStake?: ({ amount }: { amount: number }) => void;
  activeEvents?: StakingActiveEvents;
}) => {
  const { t } = useTranslation("staking");
  const [openStakingForm, setOpenStakingForm] = React.useState(false);
  const onSubmit = React.useCallback(
    ({ amount }) => {
      setOpenStakingForm(false);
      onStake(amount);
    },
    [onStake]
  );
  return (
    <>
      <StakeDialogForm
        pool={pool}
        open={openStakingForm}
        handleClose={() => setOpenStakingForm(false)}
        onSubmit={onSubmit}
      />

      <PoolCardButton
        sx={{
          width: '120px',
          height: '34px',
          borderStyle: 'solid',
          borderColor: 'white',
          borderWidth: '3px',
          '&:disabled': {
            borderColor: 'transparent',
          }
        }}
        disabled={disabled}
        type={StakingActionType.Stake}
        color="secondary"
        onClick={() => setOpenStakingForm(true)}
        activeEvents={activeEvents}
      >
        {t("action.stake.default")}
      </PoolCardButton>
    </>
  );
};
export default PoolCardStaking;
